import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Route, Switch, Link, useLocation } from 'react-router-dom';
import { Scrollbars } from 'rc-scrollbars';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      overflow: 'hidden'
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  }));

export default function ExerciseStepper(props) {
    const classes = useStyles();
    const location = useLocation();
    const scrollbar = useRef(null);
    const { t, i18n } = useTranslation();

    const activeStep = () => {
        return props.exercises.findIndex((e) => e.id == props.currentExerciseId);
    }

    const getExerciseLink = (exerciseId) => {
        if (location.pathname.includes('/exercise/')) {
          var parts = location.pathname.split('/');
          parts.pop();
          return parts.join('/') + '/' + exerciseId; 
        }
        else {
          return location.pathname + '/exercise/' + exerciseId; 
        }
    }

    useEffect(() => {
        // Scroll update
        const currentStep = activeStep();
        scrollbar && scrollbar.current && scrollbar.current.view.scroll({
          left: (currentStep * 160) - 200,
          behavior: 'smooth'
        });
    });

    return (
        <div className={classes.root}>
          <Scrollbars ref={scrollbar} autoHide autoHeight={true} style={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep()} style={{padding: '20px', width: `${160*props.exercises.length}px`, minWidth: '100%'}}>
                { 
                props.exercises.map((exercise, index) => {
                    const evalIndex = props.evaluations && props.evaluations.findIndex((e) => e.exercise_id == exercise.id);

                    return (
                        <Step key={index} completed={evalIndex >= 0} onClick={() => props.setCurrentExerciseId(exercise.id)}>
                            <StepLabel>
                                <Link>
                                  <p style={{marginTop: 0, marginBottom: '-2px'}}>{ t('Exercise') } {index + 1} </p>{ evalIndex >= 0 && <Typography component="span" variant="caption" style={{opacity: 0.6}}><AssignmentTurnedInIcon style={{fontSize: '0.9em'}}></AssignmentTurnedInIcon> { props.evaluations[evalIndex].score.toFixed(1) }</Typography> }
                                </Link>
                            </StepLabel>
                        </Step>
                    );
                })
                }
            </Stepper>
          </Scrollbars>
        </div>
    )
}