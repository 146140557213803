import React, { useState, useEffect } from "react";
import { recorderService } from '../../services/recorder.js';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import clsx from  'clsx';
import { Trans, useTranslation } from 'react-i18next';

const CustomLinearProgress = withStyles((theme) => ({
    root: {
      height: 12,
      borderRadius: 4
    }
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    range: {
        textAlign: 'center',
        fontSize: 'small',
        opacity: 0.75,
        height: '10px',
        paddingTop: '10px',
        "&:first-child": {
            borderRadius: '4px 0px 0px 4px'
        },
        "&:last-child": {
            borderRadius: '0px 4px 4px 0px'
        }
    },
    nosound: {
        backgroundColor: '#5a5a5a'
    },
    warning: {
        backgroundColor: '#b48f1e'
    },
    success: {
        backgroundColor: '#247943'
    },
    danger: {
        backgroundColor: '#8a1010'
    }
}));

const Volumeter = (props) => {
    const [value, setValue] = useState(0.0);
    const peakThreshold = 0.95;
    const classes = useStyles();

    useEffect(() => {
        const subscription = recorderService.recorderServiceOnVolumeUpdate.subscribe((volume) => {
            let newValue = Math.max(volume, value*0.66);
            setValue(newValue);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return (
        <div>
            <CustomLinearProgress color="secondary" style={{height: props.height}} variant="determinate" value={ Math.min(100, value*100) } />
            {
                props.showHelper && 
                <Grid container spacing={0} style={{marginTop: '10px'}}>
                    <Grid item xs={2} className={clsx(classes.range, classes.warning)}>
                        <Trans>low</Trans>
                    </Grid>
                    <Grid item xs={8} className={clsx(classes.range, classes.success)}>
                        <Trans>perfect</Trans>
                    </Grid>
                    <Grid item xs={2} className={clsx(classes.range, classes.danger)}>
                        <Trans>too high</Trans>
                    </Grid>
                </Grid>
            }
        </div>
    )
};

export default Volumeter;