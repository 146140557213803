import { BehaviorSubject } from 'rxjs';
import jwt from 'jwt-decode'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('plectrusUser')));

function hasTokenExpired(token) {
    if (token == null) return true;
    
    var current_time = Date.now() / 1000;
    if ( token.user.exp < current_time) {
      return true;
    }

    return false;
}

// Check if local stored token is expired
if (hasTokenExpired(currentUserSubject.value)) {
    currentUserSubject.next(null);
    localStorage.removeItem('plectrusUser');
}
else {
    // Check if token is still valid for API
    (async () => {
        const isValid = await renewAuthToken();
        if (!isValid) {
            console.debug("Expired or invalid token detected. Redirecting to login page...");
            currentUserSubject.next(null);
            localStorage.removeItem('plectrusUser');
        }
        else console.debug("Restored local user auth token (still valid)");
    })();
}

export const authenticationService = {
    login,
    renewAuthToken,
    logout,
    setLoginFromToken,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

async function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
    };

    const response = await fetch(process.env.REACT_APP_API_URL + `/login`, requestOptions);
    if (response) {
        try {
            const responseJson = await response.json();
            if (responseJson && !responseJson.err) {
                const user = {
                    "token": responseJson.data.token,
                    "user": jwt(responseJson.data.token)
                };
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('plectrusUser', JSON.stringify(user));
                currentUserSubject.next(user);
            }

            return responseJson;
        } catch (e) {
            return false;
        }
    }

    return false;
}

async function renewAuthToken() {
    if (!currentUserSubject.value || !('token' in currentUserSubject.value)) return false;
    
    let requestOptions = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUserSubject.value.token
        }
    };

    const response = await fetch(process.env.REACT_APP_API_URL + `/renewAuthToken`, requestOptions);
    if (response) {
        try {
            const responseJson = await response.json();
            if (responseJson && !responseJson.err) {
                const user = {
                    "token": responseJson.data.token,
                    "user": jwt(responseJson.data.token)
                };
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('plectrusUser', JSON.stringify(user));
                currentUserSubject.next(user);

                return true;
            }
        } catch (e) {
            return false;
        }
    }

    return false;
}

function setLoginFromToken(jwtToken) {
    try {
        const user = {
            "token": jwtToken,
            "user": jwt(jwtToken)
        };
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('plectrusUser', JSON.stringify(user));
        currentUserSubject.next(user);
        return true;
    } catch (e) {
        return false;
    }
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('plectrusUser');
    currentUserSubject.next(null);
}