import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation, Trans } from 'react-i18next';
import { apiService } from '../../services/api.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function DifficultiesDialog(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [difficulties, setDifficulties] = React.useState([]);
  const [name, setName] = React.useState("");
  const [semituneDiffAllowance, setSemituneDiffAllowance] = React.useState(0.25);
  const [semituneDiffPenalty, setSemituneDiffPenalty] = React.useState(0.5);
  const [privt, setPrivt] = React.useState(true);

  useEffect(async() => {
    const response = await apiService.getDifficulties();
    if (response && !response.err) {
        setDifficulties(response.data);
    }
  }, []);

  const createDifficulty = async () => {
    const response = await apiService.postDifficulties({
        name: name,
        semitune_diff_allowance: semituneDiffAllowance,
        semitune_diff_penalty: semituneDiffPenalty
    });

    return response;
  };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans>Difficulty levels</Trans>
      </DialogTitle>
      <DialogContent>
        <p><Trans>You can add custom difficulty levels for evaluation.</Trans></p>

        <div>
            <FormControl fullWidth={true}>
                <TextField
                    label={t("Difficulty name")}
                    variant="outlined"
                    id="newDiff-name"
                    fullWidth={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormControl>
        </div>

        { /*<p><Trans>Each note of the exercise accounts for a total score of 10.0 / <i>N</i>, where <i>N</i> is the total number of notes in the exercise. Each note's score is penalized depending on the detected amount of deviation from the reference tune. There are two values that can be adjusted for the evaluation penalty:</Trans></p> */ }

        <div>
            <p><b><Trans>Allowance threshold</Trans></b> (cents): <Trans>deviations below this threshold will be considered correct and will not apply any penalty (e.g. 25 cents = 1/4 semitune).</Trans></p>
            <FormControl fullWidth={true}>
                <TextField
                    label={t("Allowance threshold")}
                    variant="outlined"
                    fullWidth={true}
                    id="newDiff-semituneDiffAllowance"
                    value={Math.round(semituneDiffAllowance*100)}
                    onChange={(e) => {
                      setSemituneDiffAllowance(e.target.value / 100);
                      if (e.target.value / 100 > semituneDiffPenalty) {
                        setSemituneDiffPenalty((e.target.value / 100) + 0.25);
                      }
                    }}
                    type="number"
                    inputProps={{step: 1, min: 0, max: 100}}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Trans>cents</Trans></InputAdornment>
                    }}
                />
            </FormControl>
        </div>
        <div>
            <p><b><Trans>Deviation limit</Trans></b> (cents): <Trans>deviations above the <b>allowance threshold</b> will be penalized linearly from 0% to 100% of the note's score. The note will score 0.0 for deviations equal or higher than <b>deviation limit</b>.</Trans></p>
            <FormControl fullWidth={true}>
                <TextField
                    label={t("Deviation limit")}
                    variant="outlined"
                    id="newDiff-semituneDiffPenalty"
                    fullWidth={true}
                    value={Math.round(semituneDiffPenalty*100)}
                    onChange={(e) => setSemituneDiffPenalty(e.target.value / 100)}
                    type="number"
                    inputProps={{step: 1, min: Math.round(semituneDiffAllowance*100)+1, max: 100}}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Trans>cents</Trans></InputAdornment>
                    }}
                />
            </FormControl>
        </div>
        
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.onClose}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={name.length == 0}
          onClick={async () => {
            const response = await createDifficulty();
            if (response && !response.err) {
                props.onCreate(response.data.id)
            }
          }}
        >
            <Trans>Create</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  )
}