import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { authenticationService } from '../../services/auth.js';
import { Trans, withTranslation } from 'react-i18next';
import MenuDrawer from './MenuDrawer.js';

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

class MenuAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      drawerOpen: false
    };

    this.handleResize = this.handleResize.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    var shouldOpen = this.state.drawerOpen;
    if (window.innerWidth > 960) shouldOpen = true;
    this.setState({
      drawerOpen: shouldOpen
    });
  }

  handleMenu(event) {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handleClose(state) {
    this.setState({
      anchorEl: null
    });
  }

  handleDrawerToggle(state, force) {
    if (typeof state === 'undefined') state = !this.state.drawerOpen;
    if (typeof force === 'undefined') force = true;

    // Prevent resizing drawer on large screen mode (on click MenuList item)
    if (state == false && force == false && window.innerWidth > 960) return;

    this.setState({
      drawerOpen: state
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => this.handleDrawerToggle()}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Plectrus
            </Typography>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.anchorEl != null}
                onClose={this.handleClose}
              >
                <Link to="/account">
                  <MenuItem onClick={this.handleClose}><Trans>My account</Trans></MenuItem>
                </Link>
                <MenuItem onClick={() => this.props.showLogoutDialog()}><Trans>Logout</Trans></MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        {
          // Trick to add margin below AppBar
          <Toolbar />
        }
        <div style={{display: 'flex', flexGrow: 1}}>
          <MenuDrawer isOpen={this.state.drawerOpen} handleToggle={this.handleDrawerToggle} showLogoutDialog={this.props.showLogoutDialog} />
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(MenuAppBar));