import { authenticationService } from './auth.js';

export const apiService = {
    apiCall,
    register,
    getInstruments,
    getSets,
    getSessions,
    getDifficulties,
    getInstrumentSets,
    getExercises,
    getSetExercises,
    getUsers,
    getEvaluations,
    getEvaluationsLatest,
    getResetPassword,
    postSets,
    postSetExercise,
    postEvaluation,
    postDifficulties,
    putSetsOrder,
    putSetExercise,
    putSetExerciseOrder,
    putUser,
    putResetPassword,
    deleteDifficulties,
    deleteSet,
    deleteSetExercise
};

async function formattedResponse(response) {
    if (response.ok) {
        var json = await response.json();
        return json;
    }
    else {
        try {
            var json = await response.json();
            return json;
        } catch (e) {
            // continue
        }

        return {
            "err": response.statusText + '(' + response.status + ')',
            "data": null
        }
    };
}

function apiCall(method = 'GET', route, queryData) {
    let headersObj = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
    if (authenticationService.currentUserValue) headersObj['Authorization'] = 'Bearer ' + authenticationService.currentUserValue.token;

    let requestOptions = {
        method,
        headers: headersObj
    };

    let apiUrl = new URL(process.env.REACT_APP_API_URL + route)
    if (method == 'GET') {
        apiUrl.search = new URLSearchParams(queryData).toString();
    }
    else {
        requestOptions.body = JSON.stringify(queryData);
    }

    return fetch(apiUrl, requestOptions);
}

async function register(postData) {
    const response = await apiCall('POST', '/register', postData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getInstruments(filters = {}) {
    const response = await apiCall('GET', '/instruments', filters);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getSets(filters = {'deleted': 0, '_order_by': 'order_idx ASC'}) {
    const response = await apiCall('GET', '/sets', filters);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getDifficulties(filters = {'deleted': 0, '_order_by': 'id ASC'}) {
    const response = await apiCall('GET', '/difficulties', filters);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function postDifficulties(postData) {
    const response = await apiCall('POST', '/difficulties', postData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function deleteDifficulties(difficultyId) {
    const response = await apiCall('DELETE', `/difficulties/${difficultyId}`, {});
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getEvaluations(filters = {}) {
    const response = await apiCall('GET', '/evaluations', filters);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getEvaluationsLatest(filters = {}) {
    const response = await apiCall('GET', '/evaluations/latest', filters);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getSessions(filters = {}) {
    const response = await apiCall('GET', '/sessions', filters);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getUsers(filters = {}) {
    const response = await apiCall('GET', '/users', filters);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function postSets(postData) {
    const response = await apiCall('POST', '/sets', postData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function deleteSet(setId) {
    const response = await apiCall('DELETE', `/sets/${setId}`, {});
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getInstrumentSets(instrumentId, filters = {'deleted': 0, '_order_by': 'order_idx ASC'}) {
    const response = await apiCall('GET', `/sets/instruments/${instrumentId}`, filters);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getExercises(filters = {'deleted': 0, '_order_by': 'order_idx ASC'}) {
    try {
        const response = await apiCall('GET', '/exercises', filters);
        const responseJson = await formattedResponse(response);
        return responseJson;
    } catch (e) {
        return {
            err: "Unable to retrieve the exercises from the server",
            data: null
        }
    }
}

async function postSetExercise(setId, postData) {
    const response = await apiCall('POST', `/sets/${setId}/exercises`, postData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getSetExercises(setId, filters = {'deleted': 0, '_order_by': 'order_idx ASC'}) {
    try {
        const response = await apiCall('GET', `/sets/${setId}/exercises`, filters);
        const responseJson = await formattedResponse(response);
        return responseJson;
    } catch (e) {
        return {
            err: "Unable to retrieve the exercises from the server",
            data: null
        }
    }
}

async function putUser(userId, putData) {
    const response = await apiCall('PUT', `/users/${userId}`, putData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function putSetExercise(setId, exerciseId, putData) {
    const response = await apiCall('PUT', `/sets/${setId}/exercises/${exerciseId}`, putData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function putSetExerciseOrder(setId, putData) {
    const response = await apiCall('PUT', `/sets/${setId}/order`, putData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function putSetsOrder(putData) {
    const response = await apiCall('PUT', `/setsOrder`, putData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function deleteSetExercise(setId, exerciseId) {
    const response = await apiCall('DELETE', `/sets/${setId}/exercises/${exerciseId}`, {});
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function postEvaluation(postData) {
    const response = await apiCall('POST', '/evaluations', postData);
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function getResetPassword(username) {
    const response = await apiCall('GET', '/resetPassword', {"username": username});
    const responseJson = await formattedResponse(response);

    return responseJson;
}

async function putResetPassword(token, password) {
    const response = await apiCall('PUT', '/resetPassword', {"token": token, "password": password});
    const responseJson = await formattedResponse(response);

    return responseJson;
}