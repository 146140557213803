import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { authenticationService } from '../../services/auth.js';
import { Trans, useTranslation } from 'react-i18next';
import SignUp from './SignUp.js';
import ResetPasswordDialog from './ResetPasswordDialog.js';

function Copyright() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '} {new Date().getFullYear()} J. Tejada, A. Murillo, M. M. Bernabé, R. Morant, O. Lorenzo, L. Herrera y la <a href="https://www.uv.es/" target="_blank">U. de València</a> a través del Institut de Creativitat i Innovacions Educatives y el Grupo de Investigación en Educación Musical y Creatividad-ArtsLab (GIUV2016-340). Este software ha sido financiado por MCIN/ AEI /10.13039/501100011033/.
        </Typography>
      </Grid>
      <Grid item>
        <img src={`${process.env.PUBLIC_URL}/images/logos/MICINN_Gob_Web_AEI_2.jpg`} width="42%"></img>
        <img src={`${process.env.PUBLIC_URL}/images/logos/IUCIE-03.png`} width="36.5%"></img>
        <img src={`${process.env.PUBLIC_URL}/images/logos/artslab.jpg`} width="12.35%"></img>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
    position: 'absolute',
    top: '20px',
    left: '30px',
    textShadow: '4px 4px 8px rgba(0,0,0,.4)',
    letterSpacing: '3px'
  },
  image: {
    position: 'relative',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/login_background2.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);
  const [showResetPasswordDialog, setResetPasswordDialog] = React.useState(false);

  const handleSubmit = async(event) => {
    event.preventDefault();
    const response = await authenticationService.login(username, password);
    if (!response) {
      setErrorMessage("The server was unable to check your login credentials. If the problem persists, please contact us.");
    }
    else if (response.err) {
      setErrorMessage(response.err);
    }
    return false;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <Hidden smDown>
          <Typography component="h1" variant="h4" paragraph={true} className={classes.title}>
            Plectrus
          </Typography>
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" paragraph={true}>
            Plectrus
          </Typography>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <Trans>Sign in</Trans>
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={ t("E-mail address") }
              name="email"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={ t("Password") }
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            {
              errorMessage &&
              <Alert variant="outlined" severity="error">{ t(errorMessage) }</Alert>
            }
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              <Trans>{ t("Sign in") }</Trans>
            </Button>
            <Grid container justify='space-around'>
              <Grid item>
                <Link href="#" variant="body2" onClick={() => setResetPasswordDialog(true)}>
                  { t("Forgot password?") }
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" onClick={() => setShowSignUpDialog(true)}>
                  { t("Don't have an account? Sign up!") }
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
      <SignUp open={showSignUpDialog} handleOpen={setShowSignUpDialog} />
      <ResetPasswordDialog open={showResetPasswordDialog} handleOpen={setResetPasswordDialog} />
    </Grid>
  );
}