const Pitchfinder = require("pitchfinder");
const FFT = require('fft.js');

export default class PitchDetection {
    /*
    Available algorithms:
    YIN
    AMDF
    DynamicWavelet
    */
    constructor(sampleRate = 16000, algorithm = 'ACF2+') {
        this.sampleRate = sampleRate;
        this.setPitchAlgorithm(algorithm);
        this.minFrequency = 50.0; // Detected pitches below this frequency will be nulled
        this.maxFrequency = 5000.0; // Detected pitches above this frequency will be nulled
    }

    setPitchAlgorithm(algorithm) {
        switch (algorithm) {
            case "ACF2+":
                this.detectPitch = new Pitchfinder.ACF2PLUS({
                    sampleRate: this.sampleRate
                });
                break;
            case "YIN":
                this.detectPitch = new Pitchfinder.YIN({
                    probabilityThreshold: 0.97,
                    sampleRate: this.sampleRate
                });
                break;
            case "AMDF":
                this.detectPitch = new Pitchfinder.AMDF({
                    sampleRate: this.sampleRate
                });
                break;
            case "YIN+AMDF":
                this.detectPitch = [
                    new Pitchfinder.YIN({
                        probabilityThreshold: 0.97,
                        sampleRate: this.sampleRate
                    }), 
                    new Pitchfinder.AMDF({
                        sampleRate: this.sampleRate
                    })
                ];
                break;
            default:
                console.error("Pitch detection method " + algorithm + " not implemented: falling back to ACF2+");
                this.detectPitch = new Pitchfinder.ACF2PLUS({
                    sampleRate: this.sampleRate
                });
        }
    }

    // Divide in overlapped frames
    splitChunks(float32Array, chunksPerSecond, overlapPerc = 0.25) {
        const samplesPerChunk = Math.round(this.sampleRate / chunksPerSecond);
        const overlap = samplesPerChunk * overlapPerc;
        const chunks = [];

        for (var i=0; i<Math.ceil(float32Array.length / samplesPerChunk); i++) {
            var b = (i*samplesPerChunk) - overlap;
            var e = ((i+1)*samplesPerChunk) + overlap;
            if (b < 0) b = 0;
            if (e >= float32Array.length) e = float32Array.length-1;
            chunks.push(float32Array.slice(b, e));
        }

        return chunks;
    }

    getFrequencies(audioData, freqsPerSecond = 12) {
        var float32Array;
        if (audioData.constructor.name === 'Int16Array')
            float32Array = PitchDetection.int16ToFloat32(audioData);
        else if (audioData.constructor.name === 'Float32Array')
            float32Array = audioData;
        else
            return null;

        const frames = this.splitChunks(float32Array, freqsPerSecond);

        const frequencies = frames.map((f) => this.detectPitch(f));

        return frequencies.map((f) => f < this.maxFrequency && f > this.minFrequency ? f : null);
    }

    static FFT(float32Array, fftSize = 4096) {
        if (float32Array.length < fftSize) {
            const paddedArray = new Float32Array(fftSize);
            paddedArray.set(float32Array, 0);
            float32Array = paddedArray;
        }

        const f = new FFT(fftSize);
        const out = f.createComplexArray();
        f.realTransform(out, float32Array);

        const fftBins = new Float32Array(fftSize);
        for (let i=0; i<out.length; i++) {
          fftBins[i] = Math.pow(out[i*2], 2) / fftSize;
        }

        return fftBins;
    }

    static int16ToFloat32(inputArray, startIndex = 0, length = null) {
        if (length == null) length = inputArray.length;

        var output = new Float32Array(inputArray.length-startIndex);
        for (var i = startIndex; i < length; i++) {
            var int = inputArray[i];
            // If the high bit is on, then it is a negative number, and actually counts backwards.
            var float = (int >= 0x8000) ? -(0x10000 - int) / 0x8000 : int / 0x7FFF;
            output[i] = float;
        }
        return output;
    }
}