import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Route, Switch, Link } from 'react-router-dom';
import { apiService } from '../../services/api.js';
import { globalSettingsService } from '../../services/globalSettings.js';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DifficultiesDialog from './DifficultiesDialog.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TuneIcon from '@material-ui/icons/Tune';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class SetList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          errorMessage: null,
          difficultyId: 1,
          sets: [],
          difficulties: [],
          showDifficultiesDialog: false,
          showDeleteDifficultyDialog: false,
          deleteDifficultyDialogDeleting: false,
          tuningPitch: 440.0
        }

        this.deleteDifficultyDialog = this.deleteDifficultyDialog.bind(this);
        this.onTuningPitchChange = this.onTuningPitchChange.bind(this);
    }

    componentDidMount() {
        this.getSets(this.props.match.params.instrumentId);
        this.getDifficulties();

        globalSettingsService.currentSettings.subscribe(x => {
            this.setState({ tuningPitch: x['tuningPitch'] })
        });
    }
    
    async getSets(instrumentId) {
        const response = await apiService.getInstrumentSets(instrumentId);
        if (response && !response.err) {
            this.setState({
                sets: response.data.filter(s => !s.deleted)
            });
        }
        else {
            this.setState({
                errorMessage: response.err
            });
        }
    }

    async getDifficulties() {
        const response = await apiService.getDifficulties({'deleted': 0});
        if (response && !response.err) {
            this.setState({
                difficulties: response.data
            });
        }
        else {
            this.setState({
                errorMessage: response.err
            });
        }
    }

    handleDifficulty(event) {
        this.setState({
            difficultyId: parseInt(event.target.value),
        });
    }

    onTuningPitchChange(e, val) {
        globalSettingsService.updateSettings({'tuningPitch': val});
        this.setState({
            tuningPitch: val
        });
    }

    render() {
        const { t } = this.props;
        const currentDifficulty = this.state.difficulties.find((d) => d.id == this.state.difficultyId);

        if (this.state.errorMessage)
          return <Alert severity="error">{ this.state.errorMessage }</Alert>

        return (
            <Container maxwidth="lg">
                <DifficultiesDialog 
                  open={this.state.showDifficultiesDialog} 
                  onClose={(e) => { this.setState({showDifficultiesDialog: false})}} 
                  onCreate={(difficultyId) => {
                    this.getDifficulties();
                    this.setState({
                        difficultyId,
                        showDifficultiesDialog: false
                    })
                  }}
                />
                { this.deleteDifficultyDialog() }
                <div style={{padding: '3em 0'}}>
                    <Typography variant="h4" style={{marginBottom: '1em'}}><Trans>Choose an evaluation system</Trans></Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="difficultyId" name="difficultyId" value={this.state.difficultyId} onChange={this.handleDifficulty.bind(this)} style={{justifyContent: 'center'}}>
                            {
                                this.state.difficulties.map((diff) => 
                                    <FormControlLabel value={diff.id} control={<Radio />} label={t(diff.name)}/>
                                )
                            }
                        </RadioGroup>
                    </FormControl>
                    <div style={{marginTop: '1em'}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={(e) => this.setState({showDifficultiesDialog: true})}
                            startIcon={<TuneIcon />}
                        >
                            <Trans>Custom difficulty</Trans>
                        </Button>
                        {
                            currentDifficulty && (currentDifficulty.user_id != null || currentDifficulty.institution_id != null) &&
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={(e) => this.setState({showDeleteDifficultyDialog: true})}
                                startIcon={<DeleteForeverIcon />}
                                style={{marginLeft: '1em'}}
                            >
                                <Trans>Remove</Trans>
                            </Button>
                        }
                    </div>
                    <p><Trans>The evaluation scores will be more or less strict to pitch deviations depending on the selected level.</Trans></p>
                </div>
                <div>
                    <Typography id="discrete-slider" align="center" gutterBottom>
                        { t("Tuning pitch") } ({ this.state.tuningPitch } Hz)
                    </Typography>
                    <Slider
                        aria-labelledby="discrete-slider"
                        value={this.state.tuningPitch}
                        getAriaValueText={val => val + 'Hz'}
                        valueLabelDisplay="auto"
                        onChange={this.onTuningPitchChange}
                        step={0.5}
                        min={435}
                        max={445}
                        style={{maxWidth: '380px'}}
                    />
                </div>
                <div>
                    <Typography variant="h4" style={{marginBottom: '1em'}}><Trans>Choose an exercise set</Trans></Typography>
                    <Grid container spacing={3} justify="center" alignItems="center">
                        { 
                        this.state.sets.map((set) => 
                        set.num_exercises > 0 &&
                        <Grid item sm={6} key={`set-${set.id}`}>
                            <Card variant="outlined" raised={true}>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        { t(set.name.charAt(0).toUpperCase() + set.name.slice(1)) }
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <Trans defaults="{{ description }} ({{ count }} exercises)" values={{description: set.description ? t(set.description) : t(set.name)}} count={set.num_exercises}></Trans>
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: "center" }}>
                                    <Link to={`/exercises/instrument/${this.props.match.params.instrumentId}/sets/${set.id}/difficulty/${this.state.difficultyId}`}>
                                        <Button><Trans>Start</Trans></Button>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                        ) 
                        }
                    </Grid>
                </div>
            </Container>
        );
    }

    async handleDeleteDifficulty() {
        const currentDifficulty = this.state.difficulties.find((d) => d.id == this.state.difficultyId);
        if (currentDifficulty && (currentDifficulty.user_id != null || currentDifficulty.institution_id != null)) {
            const response = await apiService.deleteDifficulties(this.state.difficultyId);
            if (response && !response.err) {
                this.setState({
                    showDeleteDifficultyDialog: false,
                    deleteDifficultyDialogDeleting: false,
                    difficultyId: 1
                });
                this.getDifficulties();
            }
            else {
                this.setState({
                    deleteDifficultyDialogDeleting: false
                });
            }
        }
    }

    deleteDifficultyDialog() {
        return <Dialog
            open={this.state.showDeleteDifficultyDialog}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Trans>Delete difficulty</Trans>
            </DialogTitle>
            <DialogContent>
                <p><Trans>Are you sure you want to remove the selected difficulty?</Trans></p>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => this.setState({showDeleteDifficultyDialog: false, deleteDifficultyDialogDeleting: false})}
                >
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleDeleteDifficulty.bind(this)}
                    disabled={this.state.deleteDifficultyDialogDeleting}
                >
                    <Trans>{ this.state.deleteDifficultyDialogDeleting ? 'Removing...' : 'Remove' }</Trans>
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

export default withTranslation()(SetList);