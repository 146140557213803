import { BehaviorSubject, Subject } from 'rxjs';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc'

const recorderServiceStatus = new BehaviorSubject({
    isReady: false,
    statusCode: 0,
    statusMessage: 'Please, allow this page to access your microphone',
    desiredSampRate: 16000
});
const recorderServiceOnVolumeUpdate = new Subject(0.0);
const recorderServiceOnDataAvailable = new Subject(null);

class Recorder {
    constructor() {
        this.init();
        this.recorder = null;
        this.desiredSampRate = 16000;
        this.volumeDetectionSkipIters = 4; // skip iterations for volume calculation (leverages CPU usage)
        this.volumeDetectionCurrentIter = 0;
    }

    async init(deviceId, timeSlice) {
        console.debug('Initializing RecordRTC instance...');
        if (this.recorder != null) this.recorder.destroy();
        this.recorder = null;
        this.initCompleted = false;
        const constraints = {video: false, audio: true};
        if (typeof deviceId !== 'undefined') {
            console.debug("Selected input device: " + deviceId);
            constraints['audio'] = {'deviceId': {'exact': deviceId}};
        }
        if (typeof timeSlice === 'undefined') timeSlice = 500;

        try {
            let stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.recorder = new RecordRTCPromisesHandler(stream, {
                type: 'audio',
                mimeType: 'audio/wav',
                recorderType: StereoAudioRecorder,
                desiredSampRate: this.desiredSampRate,
                numberOfAudioChannels: 1,
                timeSlice: timeSlice,
                ondataavailable: function(blob) {
                    recorderServiceOnDataAvailable.next(blob);
                }
            });
            this.createVolumeScriptProcessor(stream);
            recorderServiceStatus.next({
                isReady: true,
                statusCode: 1,
                statusMessage: 'Microphone input available',
                desiredSampRate: this.desiredSampRate
            });
        } catch (err) {
            console.debug('Error accessing microphone input: ', err);
            recorderServiceStatus.next({
                isReady: false,
                statusCode: 100,
                statusMessage: 'Access to input microphone has been denied. Please, allow this page to access your microphone.',
                desiredSampRate: this.desiredSampRate
            })
        }
    }

    createVolumeScriptProcessor(stream) {
        if (this.altAudioContext) this.altAudioContext.close();

        this.altAudioContext = new AudioContext();
        let script = this.altAudioContext.createScriptProcessor(1024, 1, 1);

        script.onaudioprocess = async (event) => {
            this.volumeDetectionCurrentIter++;
            if (this.volumeDetectionCurrentIter % this.volumeDetectionSkipIters == 0) {
                this.volumeDetectionCurrentIter = 0;

                const volume = await detectVolume(event.inputBuffer);
                recorderServiceOnVolumeUpdate.next(volume);
            }
        };
        
        this.mic = this.altAudioContext.createMediaStreamSource(stream);
        this.mic.connect(script);
        script.connect(this.altAudioContext.destination);
    }

    isReady() {
        return this.recorder !== null;
    }

    startRecording(duration) {
        if (!this.recorder) return false;

        this.recorder.reset();

        if (duration) this.recorder.setRecordingDuration(duration);
        this.recorder.startRecording();
        return true;
    }

    async stopRecording(callback) {
        await this.recorder.stopRecording(callback);
    }

    async getBlob() {
        let blob = await this.recorder.getBlob();
        return blob;
    }

    async getBlobAudioData() {
        let blob = await this.getBlob();

        // Remove first 44 bytes from blob (wav format header)
        blob = blob.slice(44);

        let buffer = await blob.arrayBuffer();

        return new Int16Array(buffer);
    }

    async loadAudio(file) {
        function readFileAsync(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
            
                reader.onload = () => {
                    resolve(reader.result);
                };
            
                reader.onerror = reject;
            
                reader.readAsArrayBuffer(file);
            });
        }

        let fileBuffer = await readFileAsync(file);

        if (window.OfflineAudioContext) {
            const downsampleContext = new OfflineAudioContext(
                1,
                this.desiredSampRate,
                this.desiredSampRate
            );

            let arrayBuffer = await downsampleContext.decodeAudioData(fileBuffer);
            return arrayBuffer;
        }

        return null;
    }
}

const recorder = new Recorder();

async function getAudioDevices() {
    const audioDevices = {
        input: [],
        output: []
    };

    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        for (var i = 0; i !== devices.length; ++i) {
            if (devices[i].kind === 'audioinput') audioDevices['input'].push(devices[i]);
            else if (devices[i].kind === 'audiooutput') audioDevices['output'].push(devices[i]);
        }
    } catch (error) {
        console.error(error);
    }

    return audioDevices;
}

function setReadyStatus() {
    if (recorderServiceStatus.value.isReady) {
        recorderServiceStatus.next(Object.assign(recorderServiceStatus.value, {
            isReady: true,
            statusCode: 2,
            statusMessage: 'Microphone input available and ready'
        }));
    }
}

// Heuristic to detect volume from inputBuffer in range (0.0, 1.0)
const detectVolume = async (buffer) => {
    if (!buffer) return 0.0;
    const audioData = buffer.getChannelData(0);
    if (!audioData || audioData.length == 0) return 0.0;

    const sum = audioData.reduce((total, v) => total + v, 0.0);
    const maxVal = Math.max.apply(Math, audioData);
    var meanVal = sum / audioData.length;

    // if peaked, return 1.0
    if (maxVal >= 1.0) return 1.0;

    const consideredValues = audioData.filter(v => v > meanVal * 5.0);
    if (consideredValues.length > 0) meanVal = consideredValues.reduce((total, v) => total + v, 0.0) / consideredValues.length;

    return (0.8 * maxVal) + (0.2) * meanVal;
}

// Export AudioBuffer to WAV functions
function audioBufferToWav (buffer, opt) {
  opt = opt || {}

  var numChannels = buffer.numberOfChannels
  var sampleRate = buffer.sampleRate
  var format = opt.float32 ? 3 : 1
  var bitDepth = format === 3 ? 32 : 16

  var result
  if (numChannels === 2) {
    result = interleave(buffer.getChannelData(0), buffer.getChannelData(1))
  } else {
    result = buffer.getChannelData(0)
  }

  return encodeWAV(result, format, sampleRate, numChannels, bitDepth)
}

function encodeWAV (samples, format, sampleRate, numChannels, bitDepth) {
  var bytesPerSample = bitDepth / 8
  var blockAlign = numChannels * bytesPerSample

  var buffer = new ArrayBuffer(44 + samples.length * bytesPerSample)
  var view = new DataView(buffer)

  /* RIFF identifier */
  writeString(view, 0, 'RIFF')
  /* RIFF chunk length */
  view.setUint32(4, 36 + samples.length * bytesPerSample, true)
  /* RIFF type */
  writeString(view, 8, 'WAVE')
  /* format chunk identifier */
  writeString(view, 12, 'fmt ')
  /* format chunk length */
  view.setUint32(16, 16, true)
  /* sample format (raw) */
  view.setUint16(20, format, true)
  /* channel count */
  view.setUint16(22, numChannels, true)
  /* sample rate */
  view.setUint32(24, sampleRate, true)
  /* byte rate (sample rate * block align) */
  view.setUint32(28, sampleRate * blockAlign, true)
  /* block align (channel count * bytes per sample) */
  view.setUint16(32, blockAlign, true)
  /* bits per sample */
  view.setUint16(34, bitDepth, true)
  /* data chunk identifier */
  writeString(view, 36, 'data')
  /* data chunk length */
  view.setUint32(40, samples.length * bytesPerSample, true)
  if (format === 1) { // Raw PCM
    floatTo16BitPCM(view, 44, samples)
  } else {
    writeFloat32(view, 44, samples)
  }

  return buffer
}

function interleave (inputL, inputR) {
  var length = inputL.length + inputR.length
  var result = new Float32Array(length)

  var index = 0
  var inputIndex = 0

  while (index < length) {
    result[index++] = inputL[inputIndex]
    result[index++] = inputR[inputIndex]
    inputIndex++
  }
  return result
}

function writeFloat32 (output, offset, input) {
  for (var i = 0; i < input.length; i++, offset += 4) {
    output.setFloat32(offset, input[i], true)
  }
}

function floatTo16BitPCM (output, offset, input) {
  for (var i = 0; i < input.length; i++, offset += 2) {
    var s = Math.max(-1, Math.min(1, input[i]))
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
  }
}

function writeString (view, offset, string) {
  for (var i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i))
  }
}

export const recorderService = {
    recorder,
    getAudioDevices: getAudioDevices,
    setReadyStatus: setReadyStatus,
    audioBufferToWav: audioBufferToWav,
    recorderServiceOnVolumeUpdate: recorderServiceOnVolumeUpdate,
    recorderServiceStatus: recorderServiceStatus.asObservable(),
    recorderServiceOnDataAvailable: recorderServiceOnDataAvailable.asObservable(),
    get recorderServiceStatusValue () { return recorderServiceStatus.value }
};