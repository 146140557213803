import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { authenticationService } from '../../services/auth.js';
import MUIDataTable from "mui-datatables";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Moment from 'react-moment';
import moment from 'moment';
import abcjs from "abcjs";
import 'abcjs/abcjs-audio.css';

class EvaluationsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: {},
            instruments: {},
            difficulties: {},
            sets: {},
        };
        this.getFormattedEvaluations = this.getFormattedEvaluations.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }

    componentDidMount() {
        this.setState({
            users: this.props.users.reduce((obj, u) => { obj[u.id] = u; return obj; }, {}),
            instruments: this.props.instruments.reduce((obj, u) => { obj[u.id] = u; return obj; }, {}),
            difficulties: this.props.difficulties.reduce((obj, u) => { obj[u.id] = u; return obj; }, {}),
            sets: this.props.sets.reduce((obj, u) => { obj[u.id] = u; return obj; }, {})
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props)
            this.setState({
                users: this.props.users.reduce((obj, u) => { obj[u.id] = u; return obj; }, {}),
                instruments: this.props.instruments.reduce((obj, u) => { obj[u.id] = u; return obj; }, {}),
                difficulties: this.props.difficulties.reduce((obj, u) => { obj[u.id] = u; return obj; }, {}),
                sets: this.props.sets.reduce((obj, u) => { obj[u.id] = u; return obj; }, {})
            });
    }

    getColumns(t) {
        const currentUser = authenticationService.currentUserValue;

        return [
            {
                name: "date",
                label: t("Date"),
                options: {
                    filter: true,
                    filterType: 'custom',
                    sort: true,
                    customBodyRender: (value) => {
                        return <Moment format="dddd, DD MMMM YYYY, HH:mm">{ value }</Moment>
                    },
                    customFilterListRender: (v, filterList) => {
                        var str = "";

                        if (Array.isArray(v) && v.length == 2) {
                            if (v[0]) str += t("from") + ': ' + v[0];
                            if (v[1]) str += (str.length > 0 ? ' ' : '') + t("to") + ': ' + v[1];
                            if (str.length > 0) return str;
                        }
                        
                        return [];
                    },
                    customFilterListOptions: {
                        update: (filterList, filterPos, index) => {
                            filterList[index] = [null, null];
                            return filterList;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic: (date, filters) => {
                          if (Array.isArray(filters) && filters.length == 2) {
                            if (filters[0] && filters[1]) {
                                return !moment(date).isBetween(filters[0], filters[1], 'day', '[]');
                            }
                            else if (filters[0]) {
                                return !moment(date).isSameOrAfter(filters[0]);
                            }
                            else if (filters[1]) {
                                return !moment(date).isSameOrAfter(filters[1]);
                            }
                          }
                          return false;
                        },
                        display: (filterList, onChange, index, column) => {
                          return (
                            <FormControl>
                              <TextField
                                id="from-datetime"
                                label={t("Date from")}
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={filterList[index][0] || ""}
                                onChange={(e) => {
                                    if (filterList[index].length > 1 && filterList[index][1] && moment(filterList[index][1]).isValid()) {
                                        filterList[index] = [null, filterList[index][1]];
                                    }
                                    else filterList[index] = [null, null];
                                    
                                    filterList[index][0] = e.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                              />
                              <TextField
                                id="to-datetime"
                                label={t("Date to")}
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={filterList[index][1] || ""}
                                style={{marginTop: '5px'}}
                                onChange={(e) => {
                                    if (filterList[index].length > 0 && filterList[index][0] && moment(filterList[index][0]).isValid()) {
                                        filterList[index] = [filterList[index][0], null];
                                    }
                                    else filterList[index] = [null, null];
                                    
                                    filterList[index][1] = e.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                              />
                            </FormControl>
                          );
                        }
                    }
                }
            },
            {
                name: "user",
                label: t("User"),
                options: {
                    filter: true,
                    sort: true,
                    display: (currentUser.user.user.institution_id != null)
                }
            },
            {
                name: "instrument",
                label: t("Instrument"),
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "set",
                label: t("Set"),
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "exercise",
                label: t("Exercise"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        setTimeout(() => {
                          abcjs.renderAbc("abcExercise-row-" + tableMeta.rowIndex, value, {
                            staffwidth: 230,
                            scale: 0.8,
                            paddingbottom: 0,
                            paddingright: 0,
                            paddingleft: 0,
                            paddingtop: 0
                          });
                        }, 200);
                        return <div id={ "abcExercise-row-" + tableMeta.rowIndex }></div>;
                    }
                }
            },
            {
                name: "difficulty",
                label: t("Level"),
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "listens",
                label: t("Listens"),
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "score",
                label: t("Score"),
                options: {
                    filter: false,
                    sort: true,
                }
        }];
    }

    getFormattedEvaluations(t) {
        return this.props.evaluations
        .filter((ev) => ev.set_id in this.state.sets && !this.state.sets[ev.set_id].deleted)
        .map((ev) => {
            return {
                "date": ev.created_at,
                "user": ev.user_id in this.state.users && this.state.users[ev.user_id].name,
                "instrument": ev.instrument_id in this.state.instruments && t(this.state.instruments[ev.instrument_id].name),
                "set": ev.set_id in this.state.sets && this.state.sets[ev.set_id].name,
                "exercise": ev.abc_code,
                "difficulty": ev.difficulty_id in this.state.difficulties && t(this.state.difficulties[ev.difficulty_id].name),
                "listens": ev.listens,
                "score": ev.score.toFixed(1)
            };
        });
    }

    render() {
        const { t } = this.props;

        const options = {
            print: false,
            search: false,
            tableBodyMaxHeight: '60vh',
            selectableRows: 'none',
            sortOrder: {
                name: 'date',
                direction: 'desc'
            },
            textLabels: {
                body: {
                  noMatch: t("Sorry, no matching records found"),
                  toolTip: t("Sort"),
                },
                pagination: {
                  next: t("Next Page"),
                  previous: t("Previous Page"),
                  rowsPerPage: t("Rows per page:"),
                  displayRows: t("of"),
                },
                toolbar: {
                  search: t("Search"),
                  downloadCsv: t("Download CSV"),
                  print: t("Print"),
                  viewColumns: t("View Columns"),
                  filterTable: t("Filter Table"),
                },
                filter: {
                  all: t("All"),
                  title: t("FILTERS"),
                  reset: t("RESET"),
                },
                viewColumns: {
                  title: t("Show Columns"),
                  titleAria: t("Show/Hide Table Columns"),
                }
            }
        };

        return <MUIDataTable
            title={t("Evaluations results")}
            data={this.getFormattedEvaluations(t)}
            options={options}
            columns={this.getColumns(t)}
        />;
    }
}

export default withTranslation()(EvaluationsTable);