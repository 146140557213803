import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { apiService } from '../../services/api.js';
import { sessionService } from '../../services/session.js';
import ExerciseStepper from './ExerciseStepper.js';
import Exercise from './Exercise.js';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Moment from 'react-moment';

class ExerciseContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          currentSession: null,
          errorMessage: null,
          exercises: [],
          evaluations: [],
          difficulties: [],
          instruments: [],
          currentExerciseId: false,
          showNewSessionDialog: false
        }

        this.sessionServiceSubscription = null;
        this.setCurrentExerciseId = this.setCurrentExerciseId.bind(this);
        this.onEvaluationsUpdate = this.onEvaluationsUpdate.bind(this);
        this.nextExercise = this.nextExercise.bind(this);
    }

    componentDidMount() {
      this.getDifficulties();
      this.getInstruments();

      if (this.props.match.params.setId) {
        this.getExercises(parseInt(this.props.match.params.setId));
      }
        
      if (this.props.match.params.exerciseId)
        this.setCurrentExerciseId(parseInt(this.props.match.params.exerciseId));

      this.sessionServiceSubscription = sessionService.currentSession.subscribe(x => {
        this.setState({ currentSession: x });
        if (x == null) {
          sessionService.getActiveSession();
        }
        else {
          this.getEvaluations(x.id, this.props.match.params.setId);
        }
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.match.params.exerciseId != this.props.match.params.exerciseId)
        this.setCurrentExerciseId(parseInt(this.props.match.params.exerciseId));

      if (prevProps.match.params.setId != this.props.match.params.setId)
        this.getExercises(parseInt(this.props.match.params.setId));
    }

    componentWillUnmount() {
      if (this.sessionServiceSubscription != null)
        this.sessionServiceSubscription.unsubscribe();
    }

    async getDifficulties() {
      const response = await apiService.getDifficulties();
      if (response && !response.err) {
          this.setState({
              difficulties: response.data
          });
      }
      else {
          this.setState({
              errorMessage: response.err
          });
      }
    }

    async getInstruments() {
      const response = await apiService.getInstruments({enabled: 1});
      if (response && !response.err) {
          this.setState({
              instruments: response.data
          });
      }
      else {
          this.setState({
              errorMessage: response.err
          });
      }
    }

    async getExercises(setId) {
      this.setState({
        errorMessage: null
      });

      const response = await apiService.getExercises({set_id: setId});
      if (response && !response.err) {
        this.setState({
          exercises: response.data,
          currentExerciseId: (this.state.currentExerciseId ? this.state.currentExerciseId : response.data[0].id)
        });
      }
      else {
        if (response.err) {
          this.setState({
            errorMessage: response.err
          });
        }
        else {
          this.setState({
            errorMessage: "Unable to retrieve the exercises from the server"
          });
        }
      }
    }

    async getEvaluations(sessionId, setId) {
      if (sessionId == null || setId == null) return;

      const response = await apiService.getEvaluationsLatest({session_id: sessionId, set_id: setId});
      if (response && !response.err) {
        this.setState({
          evaluations: response.data
        });

        // Set current exercise to first without evaluation
        if (!this.state.currentExerciseId) {
          var setFlag = false;

          for (var i=0; i<this.state.exercises.length; i++) {
            const evalIndex = this.state.evaluations.findIndex((e) => e.exercise_id == this.state.exercises[i].id);
            if (evalIndex == -1) {
              setFlag = true;
              this.setCurrentExerciseId(this.state.exercises[i].id);
              break;
            }
          }

          if (!setFlag && this.state.exercises.length) {
            this.setCurrentExerciseId(this.state.exercises[0].id);
          }
        }
      }
    }

    async startNewSession() {
      this.setState({
        evaluations: [],
        showNewSessionDialog: false,
        currentExerciseId: false
      });
      sessionService.startNewSession();

      // Go to set selection
      this.props.history.push(`/exercises/instrument/${this.props.match.params.instrumentId}/sets`);
    }

    nextExercise() {
      const nextIndex = this.state.exercises.findIndex((e) => e.id == this.state.currentExerciseId) + 1;
      this.setCurrentExerciseId(this.state.exercises[nextIndex].id);
    }

    setCurrentExerciseId(exerciseId) {
      this.setState({
        currentExerciseId: exerciseId
      });
      this.props.history.replace({ pathname: `/exercises/instrument/${this.props.match.params.instrumentId}/sets/${this.props.match.params.setId}/difficulty/${this.props.match.params.difficultyId}/exercise/${exerciseId}`})
    }
    
    onEvaluationsUpdate() {
      this.getEvaluations(this.state.currentSession.id, this.props.match.params.setId);
    }

    render() {
      const { t } = this.props;
      const difficulty = this.state.difficulties.find((d) => d.id == this.props.match.params.difficultyId);
      const instrument = this.state.instruments.find((i) => i.id == this.props.match.params.instrumentId);

      if (this.state.errorMessage)
        return <Container maxwidth="lg" style={{marginTop: 40}}><Alert severity="error">{ t(this.state.errorMessage) }</Alert></Container>
      
      return <div>
        { this.newSessionDialog() }
        <ExerciseStepper 
          exercises={this.state.exercises}
          evaluations={this.state.evaluations}
          currentExerciseId={this.state.currentExerciseId}
          setCurrentExerciseId={this.setCurrentExerciseId} 
        />
        <Container maxwidth="lg">
          <div style={{fontSize: '0.9em', textAlign: 'right', paddingTop: '20px'}}>
            <div><strong><Trans>Instrument</Trans></strong>: { instrument && t(instrument.name) }</div>
            <div><strong><Trans>Level</Trans></strong>: { difficulty && t(difficulty.name) }</div>
            <div><strong><Trans>Session</Trans></strong>: { this.state.currentSession && <Moment format="dddd, DD MMMM YYYY, HH:mm">{ this.state.currentSession.created_at }</Moment> }</div>
            <div>
              <Button 
                variant="outlined" 
                color="primary" 
                size="small" 
                onClick={() => this.setState({showNewSessionDialog: true})} 
                style={{marginTop: '8px', textTransform: 'none'}}>
                  <Trans>Start new session</Trans>
              </Button>
              <Button 
                variant="outlined"
                size="small" 
                onClick={() => this.props.history.push(`/exercises/instrument/${this.props.match.params.instrumentId}/sets`)} 
                style={{marginTop: '8px', marginLeft: '8px', textTransform: 'none'}}>
                  <Trans>Exit</Trans>
              </Button>
            </div>
            
          </div>
          {
            this.state.currentExerciseId &&
            <Exercise 
              {...this.state.exercises.find((e) => e.id == this.state.currentExerciseId)} 
              instrumentId={this.props.match.params.instrumentId}
              instrument={instrument}
              difficultyId={this.props.match.params.difficultyId}
              difficulty={difficulty}
              currentSession={this.state.currentSession}
              audioContextReady={this.props.audioContextReady}
              onEvaluationsUpdate={this.onEvaluationsUpdate}
              nextExercise={this.nextExercise}
              isLast={this.state.exercises.findIndex((e) => e.id == this.state.currentExerciseId) == this.state.exercises.length-1}
            />
          }
        </Container>
        </div>;
    }

    newSessionDialog() {
      return <Dialog
        open={this.state.showNewSessionDialog}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans>Start a new session</Trans>
        </DialogTitle>
        <DialogContent>
          <p><Trans>If you start a new session, your current evaluations for all exercises will be discarded. Your current evaluations will not be erased, as they are automatically saved for later consultation, and you will be always able to access your sessions history.</Trans></p>
          <p><Trans>Do you want to start a new session?</Trans></p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({showNewSessionDialog: false})}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.startNewSession.bind(this)}
          >
            <Trans>New session</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    }
}

export default withTranslation()(ExerciseContainer);