import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function RemoveCurrentSetDialog(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [removing, setRemoving] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [error, setError] = React.useState(false);
  const history = useHistory();

  let removeCurrentSet = async() => {
    if (removing) return;

    setRemoving(true);
    let success = await props.deleteSet(props.currentSetId);
    setRemoving(false);
    if (success) {
        props.onClose();
    }
    else {
        setError(true);
        setAlertMessage(t('There was an error while removing the current exercise set from the database.'));
    }
  };

  if (alertMessage) {
    return (
        <Dialog
          open={props.open}
          fullWidth={true}
          disableBackdropClick={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {alertMessage}
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={() => { 
                props.onClose(); 
                setAlertMessage(''); 
                if (!error) {
                  history.push('/editor');
                }
              }}
            >
              <Trans>Close</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )
  }

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans>Remove current set</Trans>
      </DialogTitle>
      <DialogContent>
        <Trans>If you remove this set, all exercises and user evaluations will be lost permanently.</Trans>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.onClose}
          disabled={removing}
        >
          <Trans>Don't remove</Trans>
        </Button>
        <Button
          color="primary"
          className={classes.button}
          onClick={removeCurrentSet}
          disabled={removing}
        >
          <Trans>Remove this set</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  )
}