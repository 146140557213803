import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { apiService } from '../../services/api.js';
import { globalSettingsService } from '../../services/globalSettings.js';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import HearingIcon from '@material-ui/icons/Hearing';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PublishIcon from '@material-ui/icons/Publish';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Chart from "react-apexcharts";
import { audioContext, audioOptions } from '../../context/AudioContext.js';
import { recorderService } from '../../services/recorder.js';
import { invokeSaveAsDialog } from 'recordrtc'
import { authenticationService } from '../../services/auth.js';
import { pitchToNoteName } from '../../lib/abcjsAux.js';
import Volumeter from './Volumeter.js';
import InputValidationDialog from './InputValidationDialog.js';
import PitchDetection from '../../lib/PitchDetection.js';
import EvaluationHelper from '../../lib/EvaluationHelper.js';
import abcjs from "abcjs";
import debounce from 'lodash.debounce';
import 'abcjs/abcjs-audio.css';

// Feedback voz
const feedbackAudios = {
  "<5": ['/audio/yogui/rango00-50/concentrate.mp3','/audio/yogui/rango00-50/puedesmejorar.mp3','/audio/yogui/rango00-50/cuidado.mp3','/audio/yogui/rango00-50/fijatebien.mp3','/audio/gangoso/rango00-50/concentrate.mp3','/audio/gangoso/rango00-50/cuidado.mp3','/audio/gangoso/rango00-50/fijatebien.mp3','/audio/gangoso/rango00-50/no.mp3','/audio/gangoso/rango00-50/puedesmejorar.mp3'],
  "<7": ['/audio/yogui/rango50-70/okey.mp3','/audio/yogui/rango50-70/bien.mp3','/audio/yogui/rango50-70/noestamal.mp3','/audio/gangoso/rango50-70/bien.mp3','/audio/gangoso/rango50-70/noestamal.mp3','/audio/gangoso/rango50-70/ok.mp3'],
  "<8.5": ['/audio/yogui/rango70-85/superior.mp3','/audio/yogui/rango70-85/estamuybien.mp3','/audio/yogui/rango70-85/muybien.mp3','/audio/gangoso/rango70-85/estamuybien.mp3','/audio/gangoso/rango70-85/muybien.mp3','/audio/gangoso/rango70-85/superior.mp3'],
  ">8.5": ['/audio/yogui/rango80-100/increible.mp3','/audio/yogui/rango80-100/buenisimo.mp3','/audio/yogui/rango80-100/inmejorable.mp3','/audio/yogui/rango80-100/ereselmejor.mp3','/audio/yogui/rango80-100/maravillosoyogui.mp3','/audio/gangoso/rango85-100/buenisimo.mp3','/audio/gangoso/rango85-100/ereselmejor.mp3','/audio/gangoso/rango85-100/increible.mp3','/audio/gangoso/rango85-100/inmejorable.mp3','/audio/gangoso/rango85-100/maravilloso.mp3']
};

const styles = theme => ({
  root: {
    display: 'block',
    '& #exercise-paper svg *': {
      //fill: theme.palette.text.primary
    },
    '& .abcjs-staff *, & .abcjs-bar': {
      fill: theme.palette.text.hint
    },
    '& #exercise-paper svg .abcjs-note_selected': {
      fill: theme.palette.primary['main']
    },
    '& #exercise-paper svg .abcjs-note.highlight': {
      fill: theme.palette.primary['main']
    }
  },
  evaluationDialogRoot: {
    minWidth: '60vw'
  }
});

class Exercise extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          currentUser: null,
          windowWidth: 1024,
          windowHeight: 768,
          notes: null,
          duration: 0.0,
          tempo: 60,
          isPlaying: false,
          isPlayingRecording: false,
          recorderServiceStatus: null,
          canStartRecording: false,
          isRecording: false,
          recordedAudioBuffer: null,
          autoStopRecordingOnSilence: true,
          autoStopVolumeThreshold: 0.05,
          listens: 0,
          evaluationError: null,
          evaluationData: null,
          showEvaluationDialog: false,
          audioDevices: {
            input: [],
            output: []
          },
          selectedAudioInputDeviceId: null,
          countdown: 0,
          globalSettings: {}
        }

        this.abcjs = null;
        this.synthController = null;
        this.recorderService = null;
        this.pitchDetection = null;
        this.evaluationHelper = null;
        this.currentRecordingPlaying = null;
        this.isRecording = false; // Not in state for real-time handling purposes only
        this.volumeQueue = []; // Not in state for real-time handling purposes only;
        this.volumeQueueLength = 40; // Value every 1024 samples (16 values ~= 1 sec.)
        this.countdownInterval = null;
        this.playClickBuffer = null;

        this.startRecordingCountdown = this.startRecordingCountdown.bind(this);
        this.startRecording = this.startRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.playRecording = this.playRecording.bind(this);
        this.pauseRecording = this.pauseRecording.bind(this);
        this.togglePlay = this.togglePlay.bind(this);
        this.togglePlayRecording = this.togglePlayRecording.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.toggleLoop = this.toggleLoop.bind(this);
        this.download = this.download.bind(this);
        this.downloadRecording = this.downloadRecording.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.recorderOnDataAvailable = this.recorderOnDataAvailable.bind(this);
        this.evaluate = this.evaluate.bind(this);
        this.getPitchGraphData = this.getPitchGraphData.bind(this);
        this.reset = this.reset.bind(this);
        this.debouncedInitSynthController = debounce(this.initSynthController.bind(this), 1000);
        this.checkSilenceVolumeQueue = this.checkSilenceVolumeQueue.bind(this);
        this.forceStopRecording = this.forceStopRecording.bind(this);
        this.countdownDialog = this.countdownDialog.bind(this);
        this.playClick = this.playClick.bind(this);
    }

    async componentDidMount() {
      authenticationService.currentUser.subscribe(x => {
        this.setState({ currentUser: x })
      });

      globalSettingsService.currentSettings.subscribe(x => {
        this.setState({ globalSettings: x })
      });

      this.recorderService = recorderService.recorder;
      recorderService.recorderServiceStatus.subscribe(x => {
          const canStartRecording = x.isReady && this.state.notes != null;
          this.setState({ 
            recorderServiceStatus: x,
            canStartRecording: canStartRecording
          });
          if (x.desiredSampRate)
            this.pitchDetection = new PitchDetection(x.desiredSampRate);
      });

      recorderService.recorderServiceOnDataAvailable.subscribe(blob => this.recorderOnDataAvailable(blob));
      recorderService.recorderServiceOnVolumeUpdate.subscribe((volume) => {
        this.volumeQueue.push(volume);
        if (this.volumeQueue.length > this.volumeQueueLength) this.volumeQueue.shift();
      });

      if (this.props.difficulty) {
        this.evaluationHelper = new EvaluationHelper(this.state.globalSettings['tuningPitch'], this.props.difficulty.semitune_diff_penalty, this.props.difficulty.semitune_diff_allowance);
      }

      this.playClickBuffer = await fetch('/audio/click.wav').then(res => res.arrayBuffer()).then(ArrayBuffer => audioContext.decodeAudioData(ArrayBuffer));

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    async componentDidUpdate(prevProps, prevState) {
      if (this.props.difficulty && (this.evaluationHelper == null || this.props.difficultyId != prevProps.difficultyId || this.state.globalSettings['tuningPitch'] != prevState.globalSettings['tuningPitch'])) {
        this.evaluationHelper = new EvaluationHelper(this.state.globalSettings['tuningPitch'], this.props.difficulty.semitune_diff_penalty, this.props.difficulty.semitune_diff_allowance);
      }

      if (this.props.id != prevProps.id || this.props.instrumentId != prevProps.instrumentId || this.props.difficultyId != prevProps.difficultyId) {
        // Stop any playing sound / recording
        this.pause();
        this.forceStopRecording();
        this.reset();
      }
      if (this.props.abc_code && (!this.abcjs || this.props.abc_code != prevProps.abc_code)) {
        this.reset();
        this.renderAbc();
        setTimeout(() => {
          this.initSynthController();
        }, 100);
      }
      if (prevProps.audioContextReady != this.props.audioContextReady) {
        this.initSynthController();

        // Play click sound
        this.playClickBuffer = await fetch('/audio/click.wav').then(res => res.arrayBuffer()).then(ArrayBuffer => audioContext.decodeAudioData(ArrayBuffer));
      }
      else if (prevState.windowWidth != this.state.windowWidth) {
        this.renderAbc();
        this.debouncedInitSynthController();
      }
    }

    componentWillUnmount() {
      // Stop any playing sound / recording
      this.pause();
      this.forceStopRecording();
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    reset() {
      this.setState({
        isPlaying: false,
        isRecording: false,
        recordedAudioBuffer: null,
        listens: 0,
        evaluationError: null,
        evaluationData: null,
        showEvaluationDialog: false
      });
    }

    renderAbc() {
      const staffwidth = Math.min(this.state.windowWidth * 0.6, 500);
      this.abcjs = new abcjs.renderAbc("exercise-paper", this.props.abc_code, { 
        responsive: 'resize',
        staffwidth: staffwidth,
        add_classes: true,
      });
    }

    initSynthController() {
      // Clear highlighted notes
      var els = document.querySelectorAll("svg .highlight");
      for (var k=0; k<els.length; k++)
        els[k].classList.remove("highlight");

      this.synthController = new abcjs.synth.SynthController();
      this.synthController.load('#exercise-synth-hidden-controls', 
        {
          onEvent: (evt) => {
            var highlightEls = document.querySelectorAll("svg .highlight");
            for (var k=0; k<highlightEls.length; k++)
              highlightEls[k].classList.remove("highlight");
            
            for (var i=0; i<evt.elements.length; i++) {
              var note = evt.elements[i];
              for (var j=0; j<note.length; j++) {
                note[j].classList.add("highlight");
              }
            }
          },
          onFinished: () => {
            var els = document.querySelectorAll("svg .highlight");
            for (var k=0; k<els.length; k++)
              els[k].classList.remove("highlight");
          }
        },
        {
          displayPlay: true
        });
        
      this.synthController.setTune(this.abcjs[0], audioContext.state === 'running', {
        audioContext: audioContext,
        soundFontUrl: process.env.PUBLIC_URL + process.env.REACT_APP_SOUND_FONT_URL,
        soundFontVolumeMultiplier: audioOptions.synthVolume,
        program: (parseInt(this.props.instrumentId) - 1),
        // millisecondsPerMeasure: (60.0 / this.state.tempo)*1000,
        midiTranspose: this.props.instrument.transpose,
        visualObj: this.abcjs,
        sequenceCallback: (noteArray) => {
          // Modify notes duration (bigger gap between notes)
          noteArray[0] = noteArray[0].map(n => { const duration = n.end - n.start; n["end"] = Number((n["end"] - duration*0.15).toFixed(2)); return n; });

          const canStartRecording = this.state.recorderServiceStatus.isReady;
          let duration = noteArray[0][noteArray[0].length-1].end;
          duration = duration / (this.state.tempo / 180.0);

          this.setState({
            duration: duration,
            notes: noteArray[0],
            canStartRecording: canStartRecording
          });

          return noteArray;
        },
        onEnded: () => {
          this.setState({
            isPlaying: false
          })
        }
      }).then((resp) => {
        // Ready to play
        if (this.synthController) {
          this.synthController.setWarp((this.state.tempo / 180.0) * 100);
          console.debug("Ready to play", resp);
        }
      });
    }

    play() {
      if (!this.synthController) return;

      this.synthController.play();

      this.setState({
        listens: this.state.listens + 1,
        isPlaying: true
      });
    }

    togglePlay() {
      if (!this.state.isPlaying)
        this.play();
      else   
        this.pause();
    }

    pause() {
      if (!this.synthController) return;

      this.synthController.pause();
      this.synthController.restart();
      this.initSynthController();

      this.setState({
        isPlaying: false
      });
    }

    toggleLoop() {
      if (!this.synthController) return;

      this.synthController.toggleLoop();
    }

    setWarp(percent) {
      if (!this.synthController) return;

      this.synthController.setWarp(percent);
    }

    download() {
      if (!this.synthController) return;

      this.synthController.download(this.props.instrumentId + '-' + this.props.id + '.wav');
    }

    async downloadRecording() {
      let blob = await this.recorderService.getBlob();
      if (!blob) {
        blob = recorderService.audioBufferToWav(this.state.recordedAudioBuffer)
      }

      var file = new File([blob], this.props.instrumentId + '-' + this.props.id + '.wav', {
          type: 'audio/wav'
      });
      
      invokeSaveAsDialog(file, this.props.instrumentId + '-' + this.props.id + '.wav');
    }

    startRecordingCountdown() {
      this.setState({
        isRecording: true,
        recordedAudioBuffer: null,
        evaluationData: null,
        evaluationError: null,
        countdown: this.state.globalSettings['countdownSeconds']
      });

      this.playClick();
      this.countdownInterval = setInterval(() => {
        const ncd = this.state.countdown - 1;
        this.setState({
          countdown: (ncd < 0 ? 0 : ncd)
        });

        if (ncd <= 0) {
          if (this.countdownInterval) clearInterval(this.countdownInterval);
          this.startRecording();
        }
        else this.playClick();
      }, 1000);
    }

    startRecording() {
      this.currentRecordingBegin = new Date().getTime();
      this.currentRecordingFFTs = [];
      this.volumeQueue = [];
      this.setState({
        isRecording: true,
        recordedAudioBuffer: null,
        evaluationData: null,
        evaluationError: null
      });
      this.isRecording = true;

      this.recorderService.startRecording();
    }

    async forceStopRecording() {
      if (!this.recorderService) return;
      if (!this.isRecording) return;
      this.isRecording = false;

      await this.recorderService.stopRecording();
    }

    async stopRecording(isAuto = false) {
      if (!this.recorderService) return;
      if (!this.isRecording) return;
      this.isRecording = false;

      await this.recorderService.stopRecording();
      let audioData = await this.recorderService.getBlobAudioData();
      // If stoppped because of silence, trim ending silence
      if (isAuto && audioData.length > this.state.recorderServiceStatus.desiredSampRate*1.5) {
        audioData = audioData.slice(0, -(this.state.recorderServiceStatus.desiredSampRate*1.5)); // remove last 1.5 seconds (should be silence)
      }

      // Create AudioBuffer for playback
      var audioBuffer = audioContext.createBuffer(1, audioData.length, this.state.recorderServiceStatus.desiredSampRate);
      audioBuffer.copyToChannel(PitchDetection.int16ToFloat32(audioData), 0, 0);
      console.log("Duration: " + (audioData.length / this.state.recorderServiceStatus.desiredSampRate) + "s");

      this.setState({
        isRecording: false,
        recordedAudioBuffer: audioBuffer
      });

      this.currentRecordingBegin = null;
      this.currentRecordingFFTs = [];
      this.volumeQueue = [];

      this.evaluate(audioData);
    }

    playClick() {
      if (this.playClickBuffer) {
        var source = audioContext.createBufferSource();
        source.buffer = this.playClickBuffer;
        source.connect(audioContext.destination);
        source.start();
      }
    }

    playRecording() {
      if (this.state.recordedAudioBuffer) {
        var source = audioContext.createBufferSource();
        source.buffer = this.state.recordedAudioBuffer;
        source.connect(audioContext.destination);
        source.onended = () => {
          this.currentRecordingPlaying = null;
          this.setState({
            isPlayingRecording: false
          });
        };
        this.setState({
          isPlayingRecording: true
        });
        this.currentRecordingPlaying = source;
        source.start();
      }
    }

    pauseRecording() {
      if (this.currentRecordingPlaying != null) {
        this.currentRecordingPlaying.stop();
        this.currentRecordingPlaying = null;

        this.setState({
          isPlayingRecording: false
        });
      }
    }

    togglePlayRecording() {
      if (!this.state.isPlayingRecording)
        this.playRecording();
      else   
        this.pauseRecording();
    }

    async onFileUpload(e) {
      this.repeatExercise();

      let file = e.target.files[0];
      if (!file) return; 

      const audioBuffer = await this.recorderService.loadAudio(file);
      if (audioBuffer) {
        this.setState({
          recordedAudioBuffer: audioBuffer
        });

        this.evaluate(audioBuffer.getChannelData(0));
      }
    }

    evaluate(audioData) {
      const { t } = this.props;

      try {
        const detectedPitch = this.pitchDetection.getFrequencies(audioData);
        const evaluationData = this.evaluationHelper.rate(this.state.notes, detectedPitch);

        if (!evaluationData) {
          this.setState({
            evaluationError: t('The system was not able to evaluate your exercise. If the problem persists, please check your microphone settings.'),
            showEvaluationDialog: true
          });
          return;
        }

        // If some ref note was not detected/covered, show error (prevent rare alignments)
        const allNotesCovered = evaluationData.every((n) => n.detectedPitches.length > 0);
        if (!allNotesCovered) {
          this.setState({
            evaluationError: t('The exercise was not played correctly, please try again. Consider also better adjusting your microphone, reducing the ambient noise and playing in a similar tempo as the reference.'),
            showEvaluationDialog: true
          });
          return;
        }

        // Feedback audio
        const score = EvaluationHelper.getEvaluationScore(evaluationData);

        var selectedAudio = null;
        if (score < 5.0) {
          selectedAudio = feedbackAudios['<5'][Math.floor(Math.random()*feedbackAudios['<5'].length)];
        }
        else if (score < 7.0) {
          selectedAudio = feedbackAudios['<7'][Math.floor(Math.random()*feedbackAudios['<7'].length)];
        }
        else if (score < 8.5) {
          selectedAudio = feedbackAudios['<8.5'][Math.floor(Math.random()*feedbackAudios['<8.5'].length)];
        }
        else {
          selectedAudio = feedbackAudios['>8.5'][Math.floor(Math.random()*feedbackAudios['>8.5'].length)];
        }
        var audio = new Audio(selectedAudio);
        audio.play();
        
        // Register evaluation
        this.postEvaluation(evaluationData);
      } catch (err) {
        console.error(err);

        this.setState({
          evaluationError: (err && "message" in err ? err.message : err),
          showEvaluationDialog: true
        });
      }
    }

    /*
    Method called each 0.5s with input data:
    Used to auto-detect stop recording on silence
    */
    async recorderOnDataAvailable(blob) {
      if (!this.isRecording || !this.state.autoStopRecordingOnSilence) return;

      const currentTime = new Date().getTime();
      // Auto-stop only if half exercise reference duration has passed
      if (((currentTime - this.currentRecordingBegin) / 1000) > (this.state.duration / 2.0)) {
        this.checkSilenceVolumeQueue();
      }
    }

    onCountdownChange(e, val) {
      globalSettingsService.updateSettings({'countdownSeconds': val});
    }

    checkSilenceVolumeQueue() {
      var shouldStop = false;

      if (this.volumeQueue.length >= this.volumeQueueLength) {
        const volumeMean = this.volumeQueue.reduce((a,b) => a+b) / this.volumeQueue.length;
        if (volumeMean < this.state.autoStopVolumeThreshold) shouldStop = true;
      }

      if (shouldStop) {
        console.debug("Auto-stop recording on silence: silence detected!");
        this.stopRecording(true);
      }
    }

    // Deprecated in favor of checkSilenceVolumeQueue
    async checkSilenceFFT(blob) {
      var shouldStop = false;

      // Get FFT bins from last 0.5s of audio input
      if (typeof this.currentRecordingFFTs === 'object') {
        // Remove first 44 bytes from blob (wav format header)
        blob = blob.slice(44);

        const blobData = new Int16Array(await blob.arrayBuffer());
        const fftBins = PitchDetection.FFT(PitchDetection.int16ToFloat32(blobData));
        this.currentRecordingFFTs.push(fftBins);
      }

      if (this.currentRecordingFFTs.length > 3) {
        const maxFftValues = this.currentRecordingFFTs.map((fftBins) => Math.max.apply(null, fftBins));
        const maxMean = maxFftValues.reduce((prev, v) => prev+v, 0.0) / maxFftValues.length;
        const threshold = maxMean / 3.0;

        // Stop if past 3 FFT bins below threshold (1.5s)
        if (maxFftValues.slice(-3).every((v) => v < threshold))
          shouldStop = true;
      }

      if (shouldStop) {
        console.debug("Auto-stop recording on silence: silence detected!");
        this.stopRecording(true);
      }
    }

    async postEvaluation(evaluationData) {
      if (evaluationData == null) return;

      const score = EvaluationHelper.getEvaluationScore(evaluationData);
      const comments = EvaluationHelper.getEvaluationComments(evaluationData);
      
      const evaluation = {
        session_id: this.props.currentSession.id,
        exercise_id: this.props.id,
        instrument_id: this.props.instrumentId,
        difficulty_id: this.props.difficultyId,
        listens: this.state.listens,
        score: score
      };

      if (comments.length > 0) evaluation['comment'] = comments;

      const response = await apiService.postEvaluation(evaluation);

      if (response && !response.err) {
        // Warn parent
        this.props.onEvaluationsUpdate();

        this.setState({
          evaluationData: evaluationData,
          showEvaluationDialog: true
        });
      }
      else {
        console.log(response);
        this.setState({
          evaluationError: (response ? response.err : "Unable to registrate your evaluation."),
          showEvaluationDialog: true
        });
      }
    }

    repeatExercise() {
      this.setState({
        isPlaying: false,
        isRecording: false,
        recordedAudioBuffer: null,
        evaluationError: null,
        evaluationData: null,
        showEvaluationDialog: false
      });
    }

    nextExercise() {
      this.props.nextExercise();
    }

    render() {
      const { t } = this.props;

      const toolbarButtons = [
        <ButtonGroup
            orientation="vertical"
            color="primary"
            variant="contained"
          >
          <Button
            variant="contained"
            color="primary"
            startIcon={ this.state.isPlaying ? <StopIcon /> : <PlayArrowIcon /> }
            onClick={this.togglePlay}
            disabled={this.state.isRecording || this.state.isPlayingRecording}
          >
            { this.state.isPlaying ?  t('Stop') : t('Play') }
          </Button>
          {
            this.state.currentUser && 
            this.state.currentUser.user && 
            this.state.currentUser.user.user.is_superadmin &&
            this.state.duration &&
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SaveAltIcon />}
              onClick={this.download}
            >
              { t('Download') }
            </Button>
          }
        </ButtonGroup>
      ];
      if (this.state.canStartRecording) {
        toolbarButtons.push(
          <ButtonGroup
            orientation="vertical"
            color="primary"
            variant="contained"
          >
            {
              this.state.isRecording &&
              <Button
                variant="contained"
                color="primary"
                startIcon={<SettingsVoiceIcon />}
                onClick={() => this.stopRecording(false)}
                disabled={this.state.isPlaying}
              >
                { t('Finish') }
              </Button>
            }
            {
              !this.state.isRecording &&
              <Button
                variant="contained"
                color="primary"
                startIcon={<SettingsVoiceIcon />}
                onClick={this.startRecordingCountdown}
                disabled={this.state.isPlaying || this.state.isPlayingRecording}
              >
                { t('Evaluate') }
              </Button>
            }
            {
              (false && this.state.recordedAudioBuffer) &&
              <Button
                variant="contained"
                color="primary"
                startIcon={ this.state.isPlayingRecording ? <StopIcon /> : <HearingIcon /> }
                onClick={this.togglePlayRecording}
                disabled={this.state.isPlaying || this.state.isRecording}
              >
                { this.state.isPlayingRecording ?  t('Stop') : t('Listen') }
              </Button>
            }
            {
              this.state.currentUser && 
              this.state.currentUser.user && 
              this.state.currentUser.user.user.is_superadmin &&
              <Button
                variant="outlined"
                color="primary"
                startIcon={<PublishIcon />}
                onClick={()=> this.uploadFile.click()}
                disabled={this.state.isRecording || !(window.OfflineAudioContext) || !(window.FileReader)}
              >
                { t('Load') }
              </Button>
            }
          </ButtonGroup>
        );

        toolbarButtons.push(
          <div>
            <InputLabel style={{textAlign: 'left', marginBottom: 6}}>{ t("Input volume") }</InputLabel>
            <Volumeter showHelper={false}></Volumeter>
            <FormControlLabel
              control={<Switch color="primary" />}
              label={t("Auto-stop on silence")}
              labelPlacement="end"
              checked={this.state.autoStopRecordingOnSilence}
              onChange={(v) => this.setState({autoStopRecordingOnSilence: v.target.checked})}
            />
            <Typography id="discrete-slider" align="left" gutterBottom>
              { t("Time countdown") }
            </Typography>
            <Slider
              aria-labelledby="discrete-slider"
              value={this.state.globalSettings['countdownSeconds']}
              getAriaValueText={val => val + 's'}
              valueLabelDisplay="auto"
              onChange={this.onCountdownChange}
              step={2}
              marks
              min={0}
              max={8}
            />
          </div>
        )
      }

      return <div className={this.props.classes.root}>
        {
          this.state.recorderServiceStatus && this.state.recorderServiceStatus.statusCode != 2 &&
          <InputValidationDialog />
        }
        <div id="exercise-synth-hidden-controls" style={{display: 'none'}}></div>

        <input type="file" ref={(ref) => this.uploadFile = ref} style={{display: 'none'}} onChange={this.onFileUpload.bind(this)} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div id="exercise-paper"></div>
          </Grid>
          
          <Grid container className={"exercise-toolbar-buttons"} justify="center" spacing={2}>
              {
                toolbarButtons.map((b) => <Grid item>{ b }</Grid>)
              }
          </Grid>
          <Grid item xs={12}>
            { 
              this.state.recorderServiceStatus && this.state.recorderServiceStatus.statusCode >= 100 &&
              <Alert variant="outlined" severity="error">{ t(this.state.recorderServiceStatus.statusMessage) }</Alert>
            }
          </Grid>
        </Grid>
        { this.countdownDialog(t) }
        { this.scoreDialog(t) }
      </div>;
    }

    countdownDialog(t) {
      return <Dialog open={this.state.countdown > 0}>
        <DialogTitle>
          { t("Start playing in...") }
        </DialogTitle>
        <DialogContent>
          <Typography align="center" variant="h5">
            { this.state.countdown }
          </Typography>
        </DialogContent>
      </Dialog>
    }

    scoreDialog(t) {
      var score = EvaluationHelper.getEvaluationScore(this.state.evaluationData);
      score = score.toFixed(1);

      const pitchGraphData = this.getPitchGraphData(t);

      const handleClose = () => {
        this.setState({
          showEvaluationDialog: false
        });
      }

      if (this.state.evaluationError) {
        return <Dialog
            open={this.state.showEvaluationDialog}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Trans>Error</Trans>
            </DialogTitle>
            <DialogContent>
              
              <DialogContentText id="alert-dialog-description">
                <p><Trans>{ this.state.evaluationError }</Trans></p>
              </DialogContentText>

            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={handleClose}
              >
                <Trans>Close</Trans>
              </Button>
            </DialogActions>
          </Dialog>;
      }

      return <Dialog
        open={this.state.showEvaluationDialog && this.state.evaluationData != null}
        fullWidth={true}
        maxWidth="md"
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans>Evaluation results</Trans>
        </DialogTitle>
        <DialogContent>
          
          <DialogContentText id="alert-dialog-description">
            <p><Trans score={score}>You scored <strong>{{score}}</strong> out of 10.0</Trans>. <Trans>{ this.scoreToComment(score) }</Trans></p>
            { pitchGraphData && 
              <div style={{width: '100%'}}>
                <Chart {...pitchGraphData} height="240" />
              </div>
            }
          </DialogContentText>
          {
            this.state.evaluationData && this.state.evaluationData.some((ed) => ed.noteScoreComments.length) &&
              <Accordion elevation={3}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  { t('Show evaluation details') }
                </AccordionSummary>
                <AccordionDetails style={{flexWrap: 'wrap'}}>
                  <div style={{width: '100%'}}>
                    <ul>
                    {
                      this.state.evaluationData.map((ed, index) => {
                        if (ed.noteScoreComments.length) {
                          return <li>
                            <Trans>Note</Trans> #{index+1} ({pitchToNoteName[ed.referenceNote.pitch - this.props.instrument.transpose]}):
                            <ul>
                              { ed.noteScoreComments.map((comment) => <li><Trans>{ comment }</Trans></li>) }
                            </ul>
                          </li>;
                        }
                        return null;
                      })
                    }
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
          }
        </DialogContent>
        <DialogActions>
          { this.state.recordedAudioBuffer &&
            <ButtonGroup>
              <Button
                color="secondary"
                startIcon={ this.state.isPlayingRecording ? <StopIcon /> : <HearingIcon /> }
                onClick={this.togglePlayRecording}
                disabled={this.state.isPlaying || this.state.isRecording}
              >
                { this.state.isPlayingRecording ?  t('Stop') : t('Listen') }
              </Button>
              <Button
                color="secondary"
                startIcon={<SaveAltIcon />}
                onClick={this.downloadRecording}
              >
                { t('Download') }
              </Button>
            </ButtonGroup>
          }
          <Button
            color="primary"
            onClick={this.repeatExercise.bind(this)}
          >
            <Trans>Repeat this exercise</Trans>
          </Button>
          { !this.props.isLast &&
          <Button
            variant="contained"
            color="primary"
            onClick={this.nextExercise.bind(this)}
          >
            <Trans>Continue to next exercise</Trans>
          </Button>
          }
          {
            this.props.isLast &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.history.push('/exercises/instrument')}
            >
              <Trans>Go to main menu</Trans>
            </Button>
          }
        </DialogActions>
      </Dialog>;
    }

    scoreToComment(score) {
      if (score >= 7.5) return "Congratulations!";
      else if (score >= 5.0) return "Good job! You can still do it better!";
      else return "Why don't you try again?";
    }

    getPitchGraphData(t) {
      if (!this.state.evaluationData) return null;
      const noteNames = this.state.evaluationData.map(data => pitchToNoteName[data.referenceNote.pitch - this.props.instrument.transpose]);

      var series = [
        {
          name: t('Played (Hz)'),
          data: []
        },
        {
          name: t('Reference (Hz)'),
          data: this.state.evaluationData.map((data,i) => { return {"x": (i+1)*1000000, "y": data.referencePitch} }).concat([{"x": (this.state.evaluationData.length+1)*1000000, "y": this.state.evaluationData[this.state.evaluationData.length-1].referencePitch}])
        }
      ];
      
      this.state.evaluationData.forEach((data, i) => {
        data.detectedPitches.forEach((p,j) => {
          series[0].data.push(
            {
              "x": Math.round(((i+1) + (j / (data.detectedPitches.length)))*1000000),
              "y": p
            }
          )
        })
      });

      const data = {
        type: "line",
        options: {
          theme: {
            mode: 'dark'
          },
          fill: {
            opacity: 0.9
          },
          colors: ['#009688','#ff6b02'],
          chart: {
            id: "pitchGraph",
            animations: {
              enabled: false
            }
          },
          stroke: {
            curve: 'stepline',
            dashArray: [0, 8],
            width: [4, 2]
          },
          xaxis: {
            type: 'datetime',
            tickAmount: noteNames.length,
            tickPlacement: 'between',
            labels: {
              formatter: function(value, timestamp) {
                var idx = Math.floor(value / 1000000)-1;
                if (idx < 0) idx = 0;
                else if (idx >= noteNames.length) idx = noteNames.length-1;
                return noteNames[idx] + ' (#' + (idx+1) + ')';
              }
            },
            axisTicks: {
              show: true
            },
            tooltip: {
              formatter: function(value, timestamp) {
                var idx = Math.floor(value / 1000000)-1;
                if (idx < 0) idx = 0;
                else if (idx >= noteNames.length) idx = noteNames.length-1;
                return noteNames[idx];
              }
            }
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return parseInt(value)+' Hz';
              }
            }
          },
          tooltip: {
            x: { 
              formatter: function(value, timestamp) {
                var idx = Math.floor(value / 1000000)-1;
                if (idx < 0) idx = 0;
                else if (idx >= noteNames.length) idx = noteNames.length-1;
                return noteNames[idx];
              } 
            },
            y: {
              formatter: function(value, a) {
                if (value)
                  return value.toFixed(2) + ' Hz';
                return '- Hz';
              } 
            }
          }
        },
        series: series
      }

      return data;
    }    
}

export default withTranslation()(withTheme(withStyles(styles)(withRouter(Exercise))));