import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Trans, useTranslation } from 'react-i18next';
import { apiService } from '../../services/api.js';
import { authenticationService } from '../../services/auth.js';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="{ process.env.REACT_APP_BASE_URL }">
          Plectrus
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [institutionName, setInstitutionName] = React.useState("");
  const [acceptTerms, setAcceptTerms] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [registrationSuccessful, setRegistrationSuccessful] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [responseToken, setResponseToken] = React.useState("");
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);
  

  const handleSubmit = async(event) => {
    event.preventDefault();
    setErrorMessage("");
    setRegistrationSuccessful(false);
    setSubmitting(true);

    let postData = {
      username: username,
      password: password,
      name: firstName,
      surname: lastName,
      email: email
    };
    if (institutionName && institutionName.length) postData['institution_name'] = institutionName;

    try {
      const response = await apiService.register(postData);
      if (!response) {
        setErrorMessage("Unable to contact with the server. If the problem persists, please contact us.");
      }
      else if (response.err) {
        setErrorMessage(response.err);
      }
      else {
        setIsSuccessOpen(true);
        setRegistrationSuccessful(true);
        if ("token" in response.data) setResponseToken(response.data.token);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("Unable to contact with the server. If the problem persists, please contact us.");
    }

    setSubmitting(false);
  }

  const handleOnSuccessClose = () => {
    setIsSuccessOpen(false);
    setRegistrationSuccessful(false);
    if (responseToken) {
      authenticationService.setLoginFromToken(responseToken);
    }
  }

  if (registrationSuccessful)
    return (
      <Dialog
        open={isSuccessOpen}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">{ t("Registration successful!") }</DialogTitle>
        <DialogContent>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <AssignmentIndIcon />
            </Avatar>
            <br/>
            <Typography component="h1" variant="h5" paragraph={true}>
              { t("Your account has been successfully created!") }
            </Typography>
            <Typography component="p" paragraph={true}>
              { t("You can now start using Plectrus.") }
            </Typography>
            <Button 
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleOnSuccessClose}
            >
              { t("Let's go") }
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );

  return (
    <Dialog
        open={props.open}
        aria-labelledby="reg-dialog-title"
        aria-describedby="reg-dialog-description"
      >
        <DialogTitle id="reg-dialog-title">{ t("Registration form") }</DialogTitle>
        <DialogContent>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <AssignmentIndIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              { t("Sign up") }
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label={ t("First name") }
                    autoFocus
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label={ t("Last name") }
                    name="lastName"
                    autoComplete="lname"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={ t("E-mail address") }
                    inputProps={{ minlength: 4 }}
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label={ t("Username") }
                    inputProps={{ minlength: 4 }}
                    name="username"
                    autoComplete="username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={ t("Password") }
                    inputProps={{ minlength: 6 }}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="institution-content"
                      id="institution-header"
                    >
                      <Typography variant="subtitle1">
                        <Trans>Are you a teacher? Do you want to register your institution?</Trans>
                        <Typography variant="body2" color="textSecondary">
                          <Trans>You will be able to register other users (students) to access your institution's exercises and monitor their progress.</Trans>
                        </Typography>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="institution_name"
                          label={ t("Institution name") }
                          type="text"
                          id="institution_name"
                          value={institutionName}
                          onChange={e => setInstitutionName(e.target.value)}
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label={ t("I have read and accept the terms and conditions and the privacy policy.") }
                    onChange={(event) => setAcceptTerms(event.target.checked)}
                  />
                </Grid>
              </Grid>
              {
                errorMessage &&
                <Alert variant="outlined" severity="error" style={{marginTop: '12px'}}>{ t(errorMessage) }</Alert>
              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                disabled={!acceptTerms || !username || !password || !firstName || !lastName || !email || submitting}
              >
                { t("Sign Up") }
              </Button>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleOpen(false)} color="primary">
            { t("Cancel") }
          </Button>
        </DialogActions>
      </Dialog>
  );
}