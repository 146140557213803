import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { authenticationService } from '../../services/auth.js';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import MainMenu from './MainMenu';
import Login from '../Login/Login';
import ResetPassword from '../Login/ResetPassword';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#44c6d4',
      main: '#139bc1',
      dark: '#0d7896'
    },
    secondary: {
      light: '#ecb44e',
      main: '#e2a73e',
      dark: '#c39035'
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'sans-serif',
      'Arial'
    ].join(','),
  }
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };

    this.renewAuthTokenInterval = null;
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(x => {
      this.setState({ currentUser: x })
    });

    // Apply global styles to <body>
    document.body.style.color = theme.palette.text.primary;
    document.body.style.backgroundColor = theme.palette.background.default;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentUser != null && this.renewAuthTokenInterval == null) {
      this.renewAuthTokenInterval = setInterval(async () => {
        let success = await authenticationService.renewAuthToken();
        if (success) {
          console.debug("Renewed JWT user auth token");
        }
      }, 60000);
    }
  }

  componentWillUnmount() {
    if (this.renewAuthTokenInterval != null) {
      clearInterval(this.renewAuthTokenInterval);
      this.renewAuthTokenInterval = null;
    }
  }

  render() {
    const { currentUser } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          {
            !currentUser && 
            <Switch>
              <Route path="/resetPassword/:token" exact component={ResetPassword} />
              <Route component={Login} />
            </Switch>
          }
          {
            currentUser && <MainMenu />
          }
        </div>
      </ThemeProvider>
    )
  }
}

export default App;