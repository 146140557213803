import React from 'react';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { apiService } from '../../services/api.js';
import { withTheme, withStyles } from '@material-ui/core/styles';
import ExerciseEditor from './ExerciseEditor.js';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Drawer from '@material-ui/core/Drawer';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import DeleteIcon from '@material-ui/icons/Delete';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import './Editor.css';
import SetOrderDialog from './SetOrderDialog.js';
import RemoveCurrentSetDialog from './RemoveCurrentSetDialog.js';

const newExerciseAbc = 'L: 1/4\nK: C clef=treble\nC D E F G A B c|';

const styles = theme => ({
  root: {
      display: 'block',
      width: '100%',
      padding: '2em 0px',
      [theme.breakpoints.down('sm')]: {
        padding: '2em 0px 0px 0px'
      }
  },
  sheet: {
    maxHeight: '50vh',
    overflow: 'auto'
  },
  toolbarFormControl: {
    width: '100%',
    marginBottom: '12px'
  }
});

class Editor extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        sets: [],
        setsExercises: {},
        instruments: {},
        newSetInstrumentIds: null,
        newSetName: "",
        newSetDescription: "",
        newSetErrorMessage: "",
        errorMessage: "",
        showSetOrderDialog: false,
        showRemoveCurrentSetDialog: false
      }

      this.sortableExerciseListHelperContainer = React.createRef();

      this.registerNewSet = this.registerNewSet.bind(this);
      this.registerNewExercise = this.registerNewExercise.bind(this);
      this.deleteSet = this.deleteSet.bind(this);
      this.deleteExercise = this.deleteExercise.bind(this);
      this.registerLock = false;
      this.onSortEnd = this.onSortEnd.bind(this);
      this.onSetsSortEnd = this.onSetsSortEnd.bind(this);
      this.shouldSortCancel = this.shouldSortCancel.bind(this);
    }

    async init() {
      await this.getInstruments();
      await this.getSets();
      if (this.props.match.params.setId) await this.getSetExercises(this.props.match.params.setId);
    }

    async componentDidMount() {
      await this.init();
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.match.params.setId && prevProps.match.params.setId != this.props.match.params.setId) {
        this.getSetExercises(this.props.match.params.setId);
      }
    }

    async getInstruments() {
      const response = await apiService.getInstruments({enabled: 1});
      if (response && !response.err) {
        const instrumentById = response.data.reduce((obj, curr) => { obj[curr.id] = curr; return obj; }, {});
        this.setState({
          instruments: instrumentById
        });
      }
    }
    
    async getSets() {
      const response = await apiService.getSets();
      if (response && !response.err) {
        let sets = response.data.filter(s => (s.institution_id != null || s.user_id != null) && !s.deleted);
        this.setState({
          sets: sets
        });
      }
      else {
        this.setState({
          errorMessage: response.err
        });
      }
    }

    async getSetExercises(setId) {
      const { history } = this.props;

      const response = await apiService.getSetExercises(setId);
      if (response && !response.err) {
        const setsExercises = this.state.setsExercises;
        setsExercises[setId] = response.data;
        this.setState({
          setsExercises
        });

        // Load first exercise (or current one if in set)
        if (setsExercises[setId].length > 0) {
          if (this.props.match.params.exerciseId && setsExercises[setId].findIndex((e => e.id == this.props.match.params.exerciseId)) >= 0) {
            history.push(`/editor/${setId}/${this.props.match.params.exerciseId}`);
          }
          else {
            history.push(`/editor/${setId}/${setsExercises[setId][0].id}`);
          }
        }
      }
      else {
        this.setState({
          errorMessage: response.err
        });
      }
    }

    async registerNewSet() {
      const { history } = this.props;

      // Lock
      if (this.registerLock) return false;
      this.registerLock = true;

      if (this.state.newSetName) {
        const response = await apiService.postSets({
          "name": this.state.newSetName,
          "description": this.state.newSetDescription,
          "instrument_ids": [this.state.newSetInstrumentIds]
        });
        if (response && !response.err) {
          await this.getSets();

          // Create new exercise
          this.registerLock = false;
          await this.registerNewExercise(response.data.id);
        }
        else {
          this.setState({
            newSetErrorMessage: response.err
          });
        }
      }

      this.registerLock = false;
    }

    async registerNewExercise(setId = null) {
      const { history } = this.props;

      if (setId === null) setId = this.props.match.params.setId;
      if (!setId) return false;

      // Lock
      if (this.registerLock) return false;
      this.registerLock = true;

      let exerciseAbcCode = newExerciseAbc;

      // Default exercise for instrument(s)?
      let instrumentIds = [];
      if (this.props.match.params.setId && this.state.sets) {
        let setData = this.state.sets.find(s => s.id == this.props.match.params.setId);
        instrumentIds = setData.instrument_ids;
      }
      else if (this.state.newSetInstrumentIds) {
        // Currently, this.state.newSetInstrumentIds is just an integer
        instrumentIds = [this.state.newSetInstrumentIds];
      }
      instrumentIds.forEach(instId => {
        if (instId in this.state.instruments && this.state.instruments[instId]['default_abc_exercise']) {
          exerciseAbcCode = this.state.instruments[instId]['default_abc_exercise'];
        }
      });

      const response = await apiService.postSetExercise(setId, {'abc_code': exerciseAbcCode});
      if (response && !response.err) {
        await this.getSetExercises(setId);
        history.push(`/editor/${setId}/${response.data.id}`);
        this.registerLock = false;
        return true;
      }
      else {
        this.setState({
          newSetErrorMessage: response.err
        });
      }

      this.registerLock = false;
      return false;
    }

    async deleteSet(setId) {
      const { history } = this.props;

      const response = await apiService.deleteSet(setId);
      if (response && !response.err) {
        await this.getSets();
        history.push('/editor');
        return true;
      }

      return false;
    }

    async deleteExercise(setId, exerciseId) {
      const response = await apiService.deleteSetExercise(setId, exerciseId);
      if (response && !response.err) {
        await this.getSetExercises(setId);
      }
    }

    handleCurrentSetIdChange(event) {
      const { history } = this.props;

      if (event.target.value != this.props.match.params.setId) {
        if (event.target.value == null) history.push('/editor');
        else history.push(`/editor/${event.target.value}`);
      }
    }

    handleInstrumentIdChange(event) {
      this.setState({
        newSetInstrumentIds: event.target.value,
        newSetErrorMessage: ""
      });
    }

    handleNameChange(event) {
      this.setState({
        newSetName: event.target.value,
        newSetErrorMessage: ""
      });
    }

    handleDescriptionChange(event) {
      this.setState({
        newSetDescription: event.target.value,
        newSetErrorMessage: ""
      });
    }

    shouldSortCancel(event) {
      if (typeof event.target.className == 'object' && "baseVal" in event.target.className && event.target.className.baseVal.indexOf("dragHandle") > 0) return false;
      return true;
    }

    async onSortEnd({oldIndex, newIndex}) {
      let setId = this.props.match.params.setId;
      let sortedSet = arrayMoveImmutable(this.state.setsExercises[setId], oldIndex, newIndex);
      let sortedDict = {};
      sortedSet.forEach((v,i) => {
        sortedDict[v.id] = parseInt(i);
      });

      const response = await apiService.putSetExerciseOrder(setId, {'order': sortedDict});
      if (response && !response.err) {
        console.log(`Exercise order for set ID #${setId} updated successfully.`);
        let newSets = Object.assign({}, this.state.setsExercises);
        newSets[setId] = sortedSet;
        this.setState({
          setsExercises: newSets
        });
      }
      else {
        console.log(`Error updating exercise order for set ID #${setId}:`, response.err);
      }
    }
    
    async onSetsSortEnd({oldIndex, newIndex}) {
      let sortedSets = arrayMoveImmutable(this.state.sets, oldIndex, newIndex);
      let sortedDict = {};
      sortedSets.forEach((v,i) => {
        sortedDict[v.id] = parseInt(i);
      });

      const response = await apiService.putSetsOrder({'order': sortedDict});
      if (response && !response.err) {
        console.log(`Sets order updated successfully.`);
        this.setState({
          sets: sortedSets
        });
      }
      else {
        console.log("Error updating sets order", response.err);
      }
    }

    render() {
      const { t } = this.props;
      const { history } = this.props;
      let currentSet = null;
      if (this.props.match.params.setId) {
        currentSet = this.state.sets.find((s) => s.id == this.props.match.params.setId);
      }

      const activeRoute = (routeName) => {
        if (routeName == '/') return this.props.location.pathname == routeName;
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
      }

      const DragHandle = SortableHandle(() => (
        <IconButton edge="end" aria-label="reorder" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          <DragHandleIcon className="dragHandle" />
        </IconButton>
      ));

      const SortableExerciseListItem = SortableElement(({ exercise, idx }) => (
          <ListItem ContainerComponent="div" divider dense selected={activeRoute(`/editor/${this.props.match.params.setId}/${exercise.id}`)}>
            <Link to={`/editor/${this.props.match.params.setId}/${exercise.id}`}>
              <ListItemText primary={t("Exercise") + " " + (idx+1)} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => this.deleteExercise(this.props.match.params.setId, exercise.id)}>
                  <DeleteIcon />
                </IconButton>
                <DragHandle></DragHandle>
              </ListItemSecondaryAction>
            </Link>
          </ListItem>
      ));

      const SortableExerciseListContainer = SortableContainer(({items}) => {
        return (
          <List
            component="div" 
            aria-label={t("exercise list")}
            style={{maxHeight: '50vh', overflow: 'auto'}}
          >
            {
              items && items.map((e, idx) => {
                return <SortableExerciseListItem key={`ex-${e.id}`} index={idx} idx={idx} exercise={e} />
              })
            }
          </List>);
      });

      return (
      <div className={this.props.classes.root}>
        <Container>
        {
          /* Step-1: select or create new set of exercises */
          !this.props.match.params.setId &&
          <div>
            { /* Title and help text */}
            <div>
              <Typography variant="h4"><Trans>Welcome to the exercise editor</Trans></Typography>
                <p><Trans>Here you can create customized exercises. Exercises are contained within sets, which correspond to <i>learning units</i> for an specific instrument.</Trans></p>
              <br/>
            </div>
            { 
              this.state.sets.length > 0 && 
              <div>
                <Paper elevation={3}>
                  <Box p={2} mb={3}>
                    <Typography variant="h5" paragraph={true}>{ t("Edit an existing set") }</Typography>
                    <Typography paragraph={true}>
                      <Trans>
                        Select an existing set to create new exercises or modify the current ones.
                      </Trans>
                    </Typography>
                    <FormControl className={this.props.classes.toolbarFormControl}>
                      <InputLabel id="ExEd-setId-label">{ t("Exercises set") }</InputLabel>
                      <Select
                        labelId="ExEd-setId-label"
                        id="ExEd-setId"
                        value={this.props.match.params.setId}
                        onChange={this.handleCurrentSetIdChange.bind(this)}
                      >
                        <MenuItem value={0}>{ t("Select a set") }</MenuItem>
                        { 
                        Object.values(this.state.sets).map((set) => 
                          <MenuItem value={set.id}>{ t(set.name) }</MenuItem>
                        )
                        }
                      </Select>
                    </FormControl>
                    { this.state.sets.length > 1 && 
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => this.setState({showSetOrderDialog: true})}
                        startIcon={<LowPriorityIcon/>}
                        style={{marginTop: '10px', marginBottom: '18px'}}
                      ><Trans>Define sets order</Trans></Button>
                    }
                  </Box>
                </Paper>
                <div style={{ marginBottom: '20px'}}> &mdash; <Trans>or</Trans> &mdash; </div>
              </div>
            }
            <Paper elevation={3}>
              <Box p={2}>
                <Typography variant="h5" paragraph={true}>{ t("Create a new set of exercises") }</Typography>
                <Typography paragraph={true}>
                  <Trans>
                    Exercises are contained within sets. Each exercise set comprises a learning unit for a specific instrument.
                  </Trans>
                </Typography>

                <Box p={1}>
                  <FormControl className={this.props.classes.toolbarFormControl}>
                    <TextField
                      label={t("Set title/name")}
                      id="newSet-name"
                      value={this.state.newSetName}
                      onChange={this.handleNameChange.bind(this)}
                    />
                  </FormControl>
                </Box>
                <Box p={1}>
                  <FormControl className={this.props.classes.toolbarFormControl}>
                    <TextField
                      label={t("Short description")}
                      id="newSet-description"
                      value={this.state.newSetDescription}
                      onChange={this.handleDescriptionChange.bind(this)}
                      multiline
                    />
                  </FormControl>
                </Box>
                <Box p={1}>
                  <FormControl className={this.props.classes.toolbarFormControl}>
                    <InputLabel id="newSet-instrumentIds-label">{ t("Select the playing instrument for this set") }</InputLabel>
                    <Select
                      labelId="newSet-instrumentIds-label"
                      id="newSet-instrumentIds"
                      value={this.state.newSetInstrumentIds}
                      onChange={this.handleInstrumentIdChange.bind(this)}
                    >
                      { 
                      Object.values(this.state.instruments).map((instrument) => 
                        <MenuItem value={instrument.id}>{ t(instrument.name) }</MenuItem>
                      )
                      }
                    </Select>
                  </FormControl>
                </Box>
                { 
                  this.state.newSetErrorMessage &&
                  <Box p={1}>
                    <Alert variant="outlined" severity="error">{ t(this.state.newSetErrorMessage) }</Alert>
                  </Box>
                }

                <Button 
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => this.registerNewSet()}
                  disabled={!this.state.newSetInstrumentIds || !this.state.newSetDescription || !this.state.newSetName}
                >
                    <Trans>Create new set</Trans>
                </Button>
              </Box>
            </Paper>
          </div>
        }
        {
          this.props.match.params.setId &&
          <ExerciseEditor 
            instruments={this.state.instruments}
            set={currentSet}
            id={this.props.match.params.exerciseId}
          >
            { /* Right-side drawer children elements (SET tab) */}
            <div>
              <FormControl className={this.props.classes.toolbarFormControl}>
                <InputLabel id="ExEd-setId-label">{ t("Exercises set") }</InputLabel>
                <Select
                  labelId="ExEd-setId-label"
                  id="ExEd-setId"
                  value={this.props.match.params.setId}
                  onChange={this.handleCurrentSetIdChange.bind(this)}
                >
                  <MenuItem value={null}>{ t("Create new set") }</MenuItem>
                  { 
                  Object.values(this.state.sets).map((set) => 
                    <MenuItem value={set.id}>{ t(set.name) }</MenuItem>
                  )
                  }
                </Select>
              </FormControl>
              <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => history.push('/editor')}
                  startIcon={<CreateNewFolderIcon/>}
                  fullWidth
                  style={{marginTop: '10px'}}
                ><Trans>Create new set</Trans></Button>
              {
                this.props.match.params.setId &&
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => this.setState({showRemoveCurrentSetDialog: true})}
                    startIcon={<DeleteForeverIcon/>}
                    fullWidth
                    style={{marginTop: '10px', marginBottom: '18px', backgroundColor: '#AA0000'}}
                  ><Trans>Remove set</Trans></Button>
              }
              {
              this.props.match.params.setId &&
              <div ref={this.sortableExerciseListHelperContainer}>
                <InputLabel style={{ margin: '10px 0px' }}>{ t("Set exercises") }</InputLabel>
                <SortableExerciseListContainer 
                  onSortEnd={this.onSortEnd} 
                  items={this.state.setsExercises[this.props.match.params.setId]} 
                  lockAxis="y"
                  lockToContainerEdges={true}
                  helperClass="sortableHelper"
                  helperContainer={this.sortableExerciseListHelperContainer.current}
                  disableAutoscroll={true}
                  shouldCancelStart={this.shouldSortCancel}>
                </SortableExerciseListContainer>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => this.registerNewExercise(null)}
                  startIcon={<PlaylistAddIcon/>}
                  fullWidth
                  style={{marginTop: '10px'}}
                ><Trans>New exercise</Trans></Button>
              </div>
              }
            </div>
          </ExerciseEditor>
        }
        </Container>

        <SetOrderDialog
          open={this.state.showSetOrderDialog}
          onClose={() => this.setState({showSetOrderDialog: false})}
          onSortEnd={this.onSetsSortEnd}
          sets={this.state.sets}
        ></SetOrderDialog>

        { this.props.match.params.setId && 
          <RemoveCurrentSetDialog
            open={this.state.showRemoveCurrentSetDialog}
            onClose={async (updateSets) => {
              if (updateSets) await this.getSets();
              this.setState({showRemoveCurrentSetDialog: false});
            }}
            currentSetId={this.props.match.params.setId}
            deleteSet={this.deleteSet}
          ></RemoveCurrentSetDialog>
        }
      </div>
      )
    }
}

export default withRouter(withTranslation()(withTheme(withStyles(styles)(Editor))));