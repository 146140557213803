/*** Aux functions ***/

function getSemituneDiff(fCurrent, fRef, ignoreOctaveDiff = false) {
    if (ignoreOctaveDiff) {
        const octDiff = fCurrent / fRef;
        if (octDiff != 0.0) {
            if (octDiff > 1.0) fCurrent = fCurrent / Math.round(octDiff);
            else fCurrent = fCurrent * Math.round(1 / octDiff);
        }
    }

    return 12*Math.log(fCurrent/fRef)/Math.log(2);
}

function getSemituneAndOctaveDiff(fCurrent, fRef) {
    var octDiff = 0;

    const relDiff = fCurrent / fRef;
    if (relDiff != 0.0) {
        if (relDiff > 1.0) {
            octDiff = Math.round(relDiff);
            fCurrent = fCurrent / octDiff;
            octDiff = octDiff - 1;
        }
        else {
            octDiff = Math.round(1 / relDiff);
            fCurrent = fCurrent * octDiff;
            octDiff = (-1 * octDiff) + 1;
        }
    }

    return [12*Math.log(fCurrent/fRef)/Math.log(2), octDiff];
}

function fixOctave(fCurrent, fRef) {
    const relDiff = fCurrent / fRef;
    if (relDiff != 0.0) {
        if (relDiff > 1.0) {
            fCurrent = fCurrent / Math.round(relDiff);
        }
        else {
            fCurrent = fCurrent * Math.round(1 / relDiff);
        }
    }

    return fCurrent;
}

function noteFromPitch(frequency, tune=440.0) {
    var noteNum = 12 * (Math.log(frequency / tune)/Math.log(2));
    return Math.round( noteNum ) + 69;
}

function splitToChunks(arr, parts) {
    let [...array]  = arr;
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
}

function sortByFrequency(array) {
    var frequency = {};

    array.forEach(function(value) { frequency[value] = 0; });

    var uniques = array.filter(function(value) {
        return ++frequency[value] == 1;
    });

    return uniques.sort(function(a, b) {
        return frequency[b] - frequency[a];
    });
}

function countUnique(iterable) {
    return new Set(iterable).size;
}

function median(array) {
    array.slice(0).sort(function(a, b) {
        return a - b;
    });
    var mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
}
const mean = (array) => array.reduce((a, b) => a + b) / array.length;
const argFact = (compareFn) => (array) => array.map((el, idx) => [el, idx]).reduce(compareFn)[1];
const argMax = argFact((min, el) => (el[0] > min[0] ? el : min));
const argMin = argFact((max, el) => (el[0] < max[0] ? el : max));



export default class EvaluationHelper {
    constructor(
        tuningFreq = 440.0, 
        semituneDiffPenalty = 0.5, 
        semituneDiffAllowance = 0.25
    ) {
        this.tuningFreq = tuningFreq;
        this.semituneDiffPenalty = semituneDiffPenalty; // penalty range (from semituneDiffAllowance to semituneDiffAllowance+semituneDiffPenalty)
        this.semituneDiffAllowance = semituneDiffAllowance; // discard penalty for semitune diffs below this threshold
        this.debugInfo = false;
        this.warnMessageThreshold = semituneDiffAllowance + (semituneDiffPenalty/3);
        this.anomaliesLengthRatio = 1.4; // Detect anomalies on the alignments: if any note has been assigned less eval pitch values than the expected / this.anomaliesLengthRatio; use fallback approximation
    }

    rate(referenceNotes, evalPitches) {
        // Recursive algorithm to find optimum alignment between reference notes and detected pitch values
        var evaluationData = this.alignInputAndReference_Viterbi(referenceNotes, evalPitches);

        if (this.debugInfo)
            console.debug("[EvaluationHelper] alignInputAndReference_Viterbi(): ", evaluationData);

        if (evaluationData === null) return false;

        // Detect anomalies on the alignments to use fallback alignment heuristic
        var alignmentAnomalies = false;

        var relativeReferenceDurations = referenceNotes.map((n) => n.end - n.start);
        const minRefDur = Math.min(...relativeReferenceDurations);
        relativeReferenceDurations = relativeReferenceDurations.map(n => Math.round(n / minRefDur));
        const numUnits = relativeReferenceDurations.reduce((prev, curr) => prev+curr, 0);
        const samplesPerUnit = Math.floor(evalPitches.filter(p => p != null).length / numUnits);
        
        for (var i=0; i<evaluationData.length; i++) {
            if (evaluationData[i]["detectedPitches"].length < (samplesPerUnit * relativeReferenceDurations[i]) / this.anomaliesLengthRatio) {
                alignmentAnomalies = true;
                break;
            }
        }

        // Fallback alignment splitting by time information (heuristic)
        if (alignmentAnomalies) {
            if (this.debugInfo)
                console.debug("[EvaluationHelper] alignInputAndReference_Viterbi failed, falling back to alignInputAndReference_RelativeDurationSplits...");
            evaluationData = this.alignInputAndReference_RelativeDurationSplits(referenceNotes, evalPitches);
        }

        // Discard leading and trailing 10% pitch values
        // Divide resulting 80% central pitches in three subarrays: beginning 33%, middle 33%, end 33%
        // Get 3 median pitch values per note: beginning, middle, end
        evaluationData.map(ed => {
            if (ed == null || ed.detectedPitches.length == 0) {
                return null;
            }

            const numPitches = ed.detectedPitches.length;
            const centralPitches = ed.detectedPitches.slice(Math.floor(numPitches * 0.1), Math.ceil(numPitches * 0.9));

            ed.medianPitches = {
                'beginning': median(centralPitches.slice(0, Math.ceil(centralPitches.length * 0.33))),
                'middle': median(centralPitches.slice(Math.floor(centralPitches.length * 0.33), Math.ceil(centralPitches.length * 0.66))),
                'end': median(centralPitches.slice(Math.floor(centralPitches.length * 0.66), Math.ceil(centralPitches.length)))
            }

            return ed;
        });

        // Evaluation algorithm: 
        // - each of the N notes accounts for 1/N of the total score
        // - beggining, middle and end detected pitch values will be compared against the reference in terms of semitune differences
        // - each of these accounts for 1/3 of the note total score
        // - semitune differences are penalized according to semituneDiffPenalty and semituneDiffAllowance values
        //   * semitune differences below semituneDiffAllowance do not apply any penalty
        //   * semitune differences from semituneDiffAllowance to semituneDiffAllowance+semituneDiffPenalty apply a linear penalty from 0 to 100% of the note's total score
        const notesScore = 10.0 / evaluationData.length;
        evaluationData.map(ed => {
            ed.maxNoteScore = notesScore;
            ed.noteScore = notesScore;
            ed.noteScoreComments = [];
            ed.semituneDifferences = {
                'beginning': null,
                'middle': null,
                'end': null,
            };
            ed.semitunePenaltiesPerc = {
                'beginning': 0.0,
                'middle': 0.0,
                'end': 0.0,
            };

            if (ed == null || !("medianPitches" in ed)) {
                ed.noteScore = 0.0;
                ed.noteScoreComments.push("We were not able to evaluate this note.");
                return ed;
            }

            ed.semituneDifferences = {
                'beginning': getSemituneDiff(ed.medianPitches.beginning, ed.referencePitch, true),
                'middle': getSemituneDiff(ed.medianPitches.middle, ed.referencePitch, true),
                'end': getSemituneDiff(ed.medianPitches.end, ed.referencePitch, true),
            };

            // Apply penalties
            for (var [part, semituneDiff] of Object.entries(ed.semituneDifferences)) {
                if (Math.abs(semituneDiff) > this.semituneDiffAllowance) {
                    const semituneDiffAboveAllowance = Math.abs(semituneDiff) - this.semituneDiffAllowance;

                    const penaltyPerc = Math.min(1.0, semituneDiffAboveAllowance / this.semituneDiffPenalty);
                    ed.semitunePenaltiesPerc[part] = penaltyPerc;

                    ed.noteScore -= (ed.maxNoteScore * penaltyPerc) / Object.keys(ed.semituneDifferences).length;
                }
            }

            return ed;
        });

        // Add evaluation comments on each note
        evaluationData.map((ed, index) => {
            if (ed.noteScoreComments.length == 0) {
                if (ed.noteScore == 0.0) {
                    ed.noteScoreComments.push("The note was not played correctly.");
                }
                else {
                    for (var [part, semituneDiff] of Object.entries(ed.semituneDifferences)) {
                        if (Math.abs(semituneDiff) >= this.warnMessageThreshold) {
                            ed.noteScoreComments.push(`The ${part} part of the note was played ${semituneDiff > 0 ? 'higher' : 'lower'} than expected.`);
                        }
                    }
                }
            }

            return ed;
        });

        return evaluationData;
    }

    static getEvaluationScore(evaluationData) {
        if (evaluationData == null) return 0.0;

        var score = evaluationData.reduce((total, ed) => total + ed.noteScore, 0.0) || 0.0;
        score = Math.round(score * 10.0) / 10.0;

        return score;
    }

    static getEvaluationComments(evaluationData) {
        return evaluationData.reduce((str, ed, index) => {
            str += (str.length > 0 ? ". " + ed.noteScoreComments : ed.noteScoreComments);

            return str;
        }, "");
    }

    midiPitchToFreq(pitch) {
        return this.tuningFreq * Math.pow(2.0, (pitch - 69.0)/12.0);
    }

    alignInputAndReference_Viterbi(referenceNotes, evalPitches) {
        // Remove null pitches
        let notNullEvalPitches = evalPitches.filter(p => p != null);

        if (notNullEvalPitches.length == 0) return null;

        // Get reference note frequencies
        var referencePitches = referenceNotes.map((n) => this.midiPitchToFreq(n.pitch));

        // Join consecutive repeated notes and keep number of repetitions for later splitting detected pitch notes
        const referencePitchesNoRep = [referencePitches[0]];
        const referencePitchesNumReps = [1];
        var currentIndex = 0;
        var currentPitch = referencePitches[0];
        for (var i=1; i<referencePitches.length; i++) {
            if (referencePitches[i] != currentPitch) {
                referencePitchesNoRep.push(referencePitches[i]);
                referencePitchesNumReps.push(1);
                currentPitch = referencePitches[i];
                currentIndex++;
            }
            else {
                referencePitchesNumReps[referencePitchesNumReps.length-1]++;
            }
        }
        referencePitches = referencePitchesNoRep;

        if (this.debugInfo)
            console.debug("[EvaluationHelper] Reference pitches (grouped): ", referencePitches);

        const trellis = new Array(notNullEvalPitches.length).fill(0).map(() => new Array(referencePitches.length).fill(Number.MAX_VALUE));

        // Introduce an octave penalty to handle two consecutive notes with exactly an octave difference
        const octDiffPenalty = 0.1; // In semitunes

        const diffs = getSemituneAndOctaveDiff(notNullEvalPitches[0], referencePitches[0]);
        trellis[0][0] = Math.abs(diffs[0]) + (diffs[1] == 0 ? 0.0 : octDiffPenalty);

        for (let i=1; i<notNullEvalPitches.length; i++) {
            for (let j=0; j<referencePitches.length && j<=i; j++) {
                const currDiffs = getSemituneAndOctaveDiff(notNullEvalPitches[i], referencePitches[j]);
                const currLoss = Math.abs(currDiffs[0]) + (currDiffs[1] == 0 ? 0.0 : octDiffPenalty);

                if (j == 0) trellis[i][j] = trellis[i-1][j] + currLoss;
                else {
                    trellis[i][j] = Math.min(
                        trellis[i-1][j-1] + currLoss,
                        trellis[i-1][j] + currLoss
                    );
                }
            }
        }

        // Recover best (finished) path from trellis (i.e. best path that ends on j=referencePitches.length-1)
        let currentJ = referencePitches.length-1;
        var bestPath = [currentJ];
        for (let i=notNullEvalPitches.length-2; i>=0; i--) {
            if (currentJ > 0) currentJ = (trellis[i][currentJ] < trellis[i][currentJ-1] ? currentJ : currentJ-1);
            bestPath.push(currentJ);
        }
        bestPath = bestPath.reverse();

        if (this.debugInfo)
            console.debug("[EvaluationHelper] Best alignment found: ", bestPath);

        // Octave fix (set pitches to closest octave w.r.t. corresponding reference note)
        for (var i=0; i<notNullEvalPitches.length; i++) {
            const octDiff = notNullEvalPitches[i] / referencePitches[bestPath[i]];
            if (octDiff != 0.0) {
                if (octDiff > 1.0) notNullEvalPitches[i] = notNullEvalPitches[i] / Math.round(octDiff);
                else notNullEvalPitches[i] = notNullEvalPitches[i] * Math.round(1 / octDiff);
            }
        }

        const evalData = [];
        referenceNotes.forEach((n) => evalData.push({
            "referenceNote": n,
            "referencePitch": this.midiPitchToFreq(n.pitch),
            "detectedPitches": []
        }));

        // Divide consecutive notes pitches
        let alignedPitches = Array.from(Array(referencePitches.length), () => new Array());
        for (var i=0; i<notNullEvalPitches.length; i++) {
            let refIdx = bestPath[i];
            alignedPitches[refIdx].push(notNullEvalPitches[i]);
        }
        var k = 0;
        for (var i=0; i<referencePitches.length; i++) {
            if (referencePitchesNumReps[i] == 1) {
                evalData[k]["detectedPitches"] = alignedPitches[i];
                k++;
            }
            else {
                let chunks = splitToChunks(alignedPitches[i], referencePitchesNumReps[i]);
                for (var j=0; j<chunks.length; j++, k++) evalData[k]["detectedPitches"] = chunks[j];
            }
        }

        return evalData;
    }

    alignInputAndReference_RelativeDurationSplits(referenceNotes, evalPitches) {
        // Remove null pitches
        let notNullEvalPitches = evalPitches.filter(p => p != null);

        // Get reference note frequencies
        var referencePitches = referenceNotes.map((n) => this.midiPitchToFreq(n.pitch));

        // Get relative durations
        var relativeReferenceDurations = referenceNotes.map((n) => n.end - n.start);
        const minRefDur = Math.min(...relativeReferenceDurations);
        relativeReferenceDurations = relativeReferenceDurations.map(n => Math.round(n / minRefDur));
        const numUnits = relativeReferenceDurations.reduce((prev, curr) => prev+curr, 0);
        const samplesPerUnit = Math.floor(notNullEvalPitches.length / numUnits);

        const evalData = [];
        referenceNotes.forEach((n) => evalData.push({
            "referenceNote": n,
            "referencePitch": this.midiPitchToFreq(n.pitch),
            "detectedPitches": []
        }));

        // Naïve assignment: divide eval pitches equally according to reference note durations
        var startIdx = 0;
        for (var i=0; i<referencePitches.length; i++) {
            var length = relativeReferenceDurations[i] * samplesPerUnit;
            evalData[i]["detectedPitches"] = notNullEvalPitches.slice(startIdx, startIdx+length);
            startIdx += length;
        }

        // Refinement: allow moving leading/trailing eval pitches to the prev/next ref note if they are closer to that one
        var detectedMedians = evalData.map(e => median(e.detectedPitches));
        for (var i=0; i<evalData.length; i++) {
            var movingToPrev = [];
            var movingToNext = [];

            if (i > 0) {
                for (var j=0; j<Math.round(evalData[i]["detectedPitches"].length * 0.5); j++) {
                    var prevDist = Math.abs(getSemituneDiff(evalData[i]["detectedPitches"][j], detectedMedians[i-1], true));
                    var currDist = Math.abs(getSemituneDiff(evalData[i]["detectedPitches"][j], detectedMedians[i], true));

                    if (prevDist < currDist * 0.9) {
                        movingToPrev.push(evalData[i]["detectedPitches"][j]);
                    }
                    else break;
                }
            }

            if (i < evalData.length-1) {
                for (var j=evalData[i]["detectedPitches"].length-1; j>Math.round(evalData[i]["detectedPitches"].length * 0.5); j--) {
                    var nextDist = Math.abs(getSemituneDiff(evalData[i]["detectedPitches"][j], detectedMedians[i+1], true));
                    var currDist = Math.abs(getSemituneDiff(evalData[i]["detectedPitches"][j], detectedMedians[i], true));

                    if (nextDist < currDist * 0.9) {
                        movingToNext.push(evalData[i]["detectedPitches"][j]);
                    }
                    else break;
                }
                movingToNext = movingToNext.reverse();
            }

            evalData[i]["detectedPitches"] = evalData[i]["detectedPitches"].slice(movingToPrev.length, evalData[i]["detectedPitches"].length-movingToNext.length);
            if (movingToPrev.length > 0) {
                evalData[i-1]["detectedPitches"] = evalData[i-1]["detectedPitches"].concat(movingToPrev);
            }
            if (movingToNext.length > 0) {
                evalData[i+1]["detectedPitches"] = evalData[i+1]["detectedPitches"].concat(movingToNext);
            }
        }

        // Octave fix
        evalData.map(ed => {
            ed.detectedPitches = ed.detectedPitches.map(p => fixOctave(p, ed.referencePitch));

            return ed;
        });

        return evalData;
    }
}