import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { audioContext } from '../../context/AudioContext.js';
import MenuAppBar from './MenuAppBar.js';
import ExerciseContainer from '../Exercise/ExerciseContainer.js';
import Dashboard from '../Dashboard/Dashboard.js';
import Editor from '../Editor/Editor.js';
import History from '../History/History.js';
import InstrumentList from '../Exercise/InstrumentList.js';
import SetList from '../Exercise/SetList.js';
import Account from '../Account/Account.js';
import { authenticationService } from '../../services/auth.js';

const styles = theme => ({
  backgroundOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    opacity: 0.05,
    zIndex: -1,
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg_music_pattern_${theme.palette.type}.png)`,
    backgroundPosition: 'center'
  }
});

class MainMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          audioContextReady: false,
          showLogoutDialog: false
        }

        this.checkAudioContextStateInterval = null;
        this.handleAudioContextDialogClose = this.handleAudioContextDialogClose.bind(this);
        this.dialogInitializeAudio = this.dialogInitializeAudio.bind(this);
    }

    componentDidMount() {
      if (audioContext.state == 'running') {
        this.setState({
          audioContextReady: true
        });
      }
      else {
        this.checkAudioContextStateInterval = setInterval(() => {
          if (audioContext.state == 'running') {
            this.setState({
              audioContextReady: true
            });
            clearInterval(this.checkAudioContextStateInterval);
            this.checkAudioContextStateInterval = null;
          }
        }, 1000);
      }
    }

    handleAudioContextDialogClose() {
      if (audioContext.state == 'suspended') {
        audioContext.resume().then(() => {
          // abcjs hack: force update active AudioContext
          window.abcjsAudioContext = audioContext;
          this.setState({
            audioContextReady: true
          });
        }).catch((err) => {
          console.error("Unable to resume AudioContext.");
        });
      }
      else if (audioContext.state == 'running') {
        this.setState({
          audioContextReady: true
        });
      }
    }

    render() {
      const { classes } = this.props;

      return (
        <div>
          <div className={classes.backgroundOverlay}></div>
          <MenuAppBar showLogoutDialog={() => this.setState({showLogoutDialog: true})}>
            <div style={{ flexGrow: 1, flexWrap: 'wrap', overflow: 'hidden' }}>
              <Switch>
                <Route path="/" exact component={Dashboard}/>
                <Route path="/account" exact component={Account} />
                <Route path="/editor" exact component={Editor} />
                <Route path="/editor/:setId" exact component={Editor} />
                <Route path="/editor/:setId/:exerciseId" exact component={Editor} />
                <Route path="/exercises/instrument/" exact component={InstrumentList} />
                <Route path="/exercises/instrument/:instrumentId/sets" exact component={SetList} />
                <Route path="/exercises/instrument/:instrumentId/sets/:setId/difficulty/:difficultyId" exact render={(props) => <ExerciseContainer {...props} audioContextReady={this.state.audioContextReady} />} />
                <Route path="/exercises/instrument/:instrumentId/sets/:setId/difficulty/:difficultyId/exercise/:exerciseId" exact render={(props) => <ExerciseContainer {...props} audioContextReady={this.state.audioContextReady}/>} />
                <Route path="/history" exact component={History} />
              </Switch>
              { this.dialogInitializeAudio() }
              { this.dialogConfirmLogout() }
            </div>
          </MenuAppBar>
        </div>
      );
    }

    dialogInitializeAudio() {
      return (
        <Dialog
          open={!this.state.audioContextReady}
          onClose={this.handleAudioContextDialogClose}
          disableBackdropClick={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Trans>Initializing audio subsystem</Trans>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Trans>Please, allow this page to access your microphone if requested. If microphone access was already allowed just press <i>Continue</i>.</Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAudioContextDialogClose} color="primary">
              <Trans>Continue</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )
    }

    dialogConfirmLogout() {
      return (
        <Dialog
          open={this.state.showLogoutDialog}
          onClose={() => this.setState({showLogoutDialog: false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Trans>You're about to sign out</Trans>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Trans>Are you sure you want to log out and exit Plectrus?</Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({showLogoutDialog: false})} color="primary">
              <Trans>Stay</Trans>
            </Button>
            <Button onClick={() => authenticationService.logout()} color="secondary">
              <Trans>Exit</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
}

export default withTranslation()(withStyles(styles)(MainMenu));