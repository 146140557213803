import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment'
import 'moment/locale/es'

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'es',
    fallbackLng: 'en',
    saveMissing: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
      wait: true
    },
    debug: process.env.NODE_ENV !== "production",
    backend: {
      loadPath: () => {
        return `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`;
      }
    }
  });
  
  i18n.on('languageChanged', function(lng) {
    moment.locale(lng);
  });


export default i18n;
