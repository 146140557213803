import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { recorderService } from '../../services/recorder.js';
import { useTranslation, Trans } from 'react-i18next';
import Volumeter from './Volumeter.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function InputValidationDialog(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [audioDevices, setAudioDevices] = React.useState({input: [], output: []});
  const [selectedInputDevice, setSelectedInputDevice] = React.useState("");
  
  const handleNext = async () => {
    if (activeStep == 1) await handleClose();
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = async() => {
    await recorderService.recorder.init(selectedInputDevice, 500);
    recorderService.setReadyStatus();
  };

  const handleInputDeviceId = async(event) => {
    let deviceId = event.target.value;

    await recorderService.recorder.init(deviceId, 100);
    setSelectedInputDevice(deviceId);
  }

  useEffect(async() => {
    let audioDevices = await recorderService.getAudioDevices();
    setAudioDevices(audioDevices);
  }, []);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans>Check your microphone volume</Trans>
      </DialogTitle>
      <DialogContent>
        <p><Trans>Please, adjust your microphone volume before starting playing. If not adjusted correctly, the evaluation might not work properly.</Trans></p>
        <Stepper activeStep={activeStep} orientation="vertical">
          { /* Step 1 */ }
          <Step key={t('Select the input device')}>
            <StepLabel>{t('Select the input device')}</StepLabel>
            <StepContent>
                <DialogContentText id="alert-dialog-description">
                    <p><Trans>Please, select your input device.</Trans></p>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel>{ t("Input device") }</InputLabel>
                        <Select
                        displayEmpty
                        value={selectedInputDevice}
                        onChange={handleInputDeviceId}
                        >
                        {
                            audioDevices.input.map((device, idx) => {
                                let label = device.label || t("Microphone") + " " + idx;
                                return <MenuItem value={device.deviceId}>{ label }</MenuItem>
                            })
                        }
                        </Select>
                    </FormControl>
                </DialogContentText>
            </StepContent>
          </Step>

          { /* Step 2 */ }
          <Step key={t('Test your microphone volume')}>
            <StepLabel>{t('Test your microphone volume')}</StepLabel>
            <StepContent>
              <DialogContentText id="alert-dialog-description">
                <p><Trans>Play your instrument at the same distance and strength you will be playing the exercises. Please check that the input volume is mostly within the recommended range. If not, adjust the microphone input level from your operating system settings.</Trans></p>
                <InputLabel style={{fontWeight: 'bold', marginBottom: 8}}>{ t("Input volume") }</InputLabel>
                <Volumeter height={20} showHelper={true}></Volumeter>
              </DialogContentText>
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
        >
          <Trans>Back</Trans>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disabled={selectedInputDevice == ""}
        >
          {activeStep === 1 ? t('Start playing') : t('Next')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}