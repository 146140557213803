import { BehaviorSubject } from 'rxjs';
import { authenticationService } from './auth.js';

const currentSessionSubject = new BehaviorSubject(null);

export const sessionService = {
    getActiveSession,
    startNewSession,
    currentSession: currentSessionSubject.asObservable(),
    get currentSessionValue () { return currentSessionSubject.value }
};

async function getActiveSession() {
    let requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token
        }
    };

    const response = await fetch(process.env.REACT_APP_API_URL + `/sessions/${authenticationService.currentUserValue.user.user.id}/active`, requestOptions);
    if (response) {
        try {
            const responseJson = await response.json();
            if (responseJson && !responseJson.err) {
                currentSessionSubject.next(responseJson.data);
            }

            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    return false;
}

async function startNewSession() {
    let requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token
        }
    };

    const response = await fetch(process.env.REACT_APP_API_URL + '/sessions', requestOptions);
    if (response) {
        try {
            const responseJson = await response.json();
            if (responseJson && !responseJson.err) {
                currentSessionSubject.next(responseJson.data);
            }

            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    return false;
}