import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { apiService } from '../../services/api.js';
import { withTheme, withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { clefs, keys, allPitches, accidentals } from '../../lib/abcjsAux.js';
import abcjs from "abcjs";
import { Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import './ExerciseEditor.css';

const TAB_SET = 0;
const TAB_EXERCISE = 1;
const TAB_ABC_CODE = 2;

const styles = theme => ({
    root: {
        display: 'block',
        '& svg *': {
            fill: theme.palette.text.primary
        },
        '& .abcjs-staff *, & .abcjs-bar': {
            fill: theme.palette.text.hint
        },
        '& svg .abcjs-note_selected, svg .abcjs-note_selected *': {
            fill: theme.palette.primary['main']
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
        width: 'calc(100% - 280px)',
        marginTop: '20px'
    },
    sheet: {
      maxHeight: '50vh',
      overflow: 'auto'
    },
    drawerRoot: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-16px',
        marginRight: '-16px'
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '-25px',
        marginRight: '-24px'
      }
    },
    drawerPaper: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'block',
        position: 'relative'
      },
      marginTop: '64px',
      width: '280px'
    },
    tabRoot: {
      minWidth: '80px !important',
      fontSize: '12px'
    },
    toolbarFormControl: {
      width: '100%',
      marginBottom: '12px'
    }
  });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
    </div>
  );
}

const initState = {
  abcCode: '',
  abcKey: 'C',
  abcClef: 'treble',
  abcMeter: [4,4],
  abcUnitLength: 4,
  abcNotes: '',
  currentTab: 0,
  hasChanges: false,
  currentNoteSelected: null
};

class ExerciseEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          ...initState,
          snackbarMessage: '',
          tempo: 36,
          instrumentId: 41,
          instrumentList: [],
          currentNoteSelected: null
        };

        this.abcEditor = null;
        this.handleNoteClick = this.handleNoteClick.bind(this);
        this.initAbcjsEditor = this.initAbcjsEditor.bind(this);
        this.abcTextField = React.createRef();
        this.handleAccidentalButton = this.handleAccidentalButton.bind(this);
    }

    async componentDidMount() {
      this.init();
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.id != this.props.id) {
        this.init();
        return;
      }

      if (prevProps.set != this.props.set && this.props.set) {
        this.setState({
          instrumentId: this.props.set.instrument_ids[0]
        });
        return;
      }

      if (!this.props.set) {
        return;
      }

      if (prevProps.set && (prevProps.set.id != this.props.set.id || prevProps.id != this.props.id)) {
        this.init();
        return;
      }

      if (this.state.currentTab != TAB_ABC_CODE) {
        if (prevState.abcClef != this.state.abcClef || prevState.abcKey != this.state.abcKey || prevState.abcMeter != this.state.abcMeter || prevState.abcUnitLength != this.state.abcUnitLength || prevState.abcNotes != this.state.abcNotes) {
          this.setState({
            abcCode: this.stateToABC()
          });
        }
      }

      if (prevState.instrumentId != this.state.instrumentId || prevProps.width != this.props.width) {
        this.initAbcjsEditor();
      }

      if (prevState.abcCode != this.state.abcCode) {
        if (this.abcEditor) this.abcEditor.paramChanged({});
      }
    }

    async init() {
      this.setState({
        ...initState
      });

      if (this.props.set) {
        this.setState({
          instrumentId: this.props.set.instrument_ids[0]
        });
      }

      if (this.props.id) {
        await this.getExercise();
        this.initAbcjsEditor();
        this.onAbcCodeChange(true);
      }
    }

    initAbcjsEditor() {
      const { width } = this.props;

      var scale = 2.0;
      var staffwidth = 740;

      if (width == 'md') {
        scale = 1.0;
        staffwidth = 500;
      }
      else if (width == 'sm' || width == 'xs') {
        scale = 1.0;
        staffwidth = 300;
      }

      this.abcEditor = new abcjs.Editor("ExEd-abc", { 
        canvas_id: "ExEd-paper", 
        warnings_id:"ExEd-warnings",
        synth: {
          el: '#ExEd-synth',
          options: {
            soundFontUrl: process.env.PUBLIC_URL + process.env.REACT_APP_SOUND_FONT_URL,
            program: (parseInt(this.state.instrumentId) - 1),
            displayLoop: false, 
            displayRestart: true, 
            displayPlay: true, 
            displayProgress: true, 
            displayWarp: false,
            midiTranspose: (this.state.instrumentId in this.props.instruments ? this.props.instruments[this.state.instrumentId].transpose : 0),
            sequenceCallback: (noteArray) => {
              // Modify notes duration (bigger gap between notes)
              noteArray[0] = noteArray[0].map(n => { const duration = n.end - n.start; n["end"] = Number((n["end"] - duration*0.15).toFixed(2)); return n; });

              return noteArray;
            }
          },
        },
        onchange: this.onAbcCodeChange.bind(this),
        abcjsParams: {
          //responsive: 'resize',
          staffwidth: staffwidth,
          scale: scale,
          add_classes: true,
          clickListener: this.handleNoteClick,
          dragging: true,
          selectTypes: ['note', 'clef', 'keySignature', 'voiceName', 'tempo'],
          format: {
            stretchlast: 0
          }
        }
      });
      this.abcEditor.synth.synthControl.setWarp((this.state.tempo / 180.0) * 100);
    }

    handleInstrumentIdChange(event) {
      this.setState({
        instrumentId: event.target.value
      });
      this.abcEditor.synthParamChanged({
        program: (parseInt(this.props.instruments[event.target.value].gm_id) - 1)
      });
    }

    handleClefChange(event) {
      this.setState({
        abcClef: event.target.value,
        hasChanges: true
      });
    }

    handleKeyChange(event) {
      this.setState({
        abcKey: event.target.value,
        hasChanges: true
      });
    }
 
    handleNoteClick(abcelem, tuneNumber, classes, analysis, drag, mouseEvent) {
      /*
      console.log(1, this.abcEditor);
      console.log(2, abcelem);
      console.log(3, tuneNumber);
      console.log(4, drag);
      */
      
      if (drag.step != 0 && abcelem.endChar < this.state.abcCode.length) {
        const currentNoteText = this.state.abcCode.substring(abcelem.startChar, abcelem.endChar).trim();
        
        const currentPitchIndex = allPitches.indexOf(currentNoteText.replace(/[^a-gA-G',]/gi,''));
        if (currentPitchIndex >= 0) {
          var newPitch = allPitches[currentPitchIndex - drag.step];
          // Include accidentals and other mods
          newPitch = currentNoteText.replace(/([a-gA-G',]+)/gi, newPitch);
          if (this.state.abcCode[abcelem.endChar-1] == ' ') newPitch += ' ';
          this.setState({
            abcCode: this.state.abcCode.substring(0, abcelem.startChar) + newPitch + this.state.abcCode.substring(abcelem.endChar),
            hasChanges: true
          });
        }
      }

      this.setState({
        currentNoteSelected: abcelem
      });

      // Focus ABC code on note click (corresponding note text automatically selected)
      if (this.state.currentTab == TAB_ABC_CODE) {
        setTimeout(() => {
          this.abcTextField && this.abcTextField.current.focus();
        }, 100);
      }
    }

    handleAbcTextFieldChange(event) {
      this.setState({
        abcCode: event.target.value,
        hasChanges: true
      })
    }

    handleTabChange(event, value) {
      this.setState({
        currentTab: value
      });
    }

    handleAccidentalButton(event, accChar = '^') {
      if (this.state.currentNoteSelected) {
        const abcelem = this.state.currentNoteSelected;
        // If selected element is clef or key signature, skip
        if (abcelem.el_type == 'clef' || abcelem.el_type == 'keySignature') return;

        const currentNoteText = this.state.abcCode.substring(abcelem.startChar, abcelem.endChar).trim();

        const accidentalsRegexp = new RegExp("(["+accidentals.join('')+"]+)", "gi");
        var newNoteText = accChar + currentNoteText.replace(accidentalsRegexp, '');
        if (this.state.abcCode[abcelem.endChar-1] == ' ') newNoteText += ' ';
        this.setState({
          abcCode: this.state.abcCode.substring(0, abcelem.startChar) + newNoteText + this.state.abcCode.substring(abcelem.endChar),
          hasChanges: true
        });
      }
    }
    
    async getExercise() {
      const response = await apiService.getExercises({id: this.props.id});
      if (response && !response.err) {
        if (response.data.length > 0) {
          this.setState({
            abcCode: response.data[0].abc_code,
            hasChanges: false
          });
        }
      }
    }

    async saveChanges() {
      const { t } = this.props;

      const response = await apiService.putSetExercise(this.props.set.id, this.props.id, {'abc_code': this.state.abcCode});
      if (response && !response.err) {
        this.setState({
          snackbarMessage: t('Exercise changes were successfully saved.'),
          hasChanges: false
        });
      }
      else {
        this.setState({
          snackbarMessage: t('Sorry, your exercise changes could not be saved: ') + t(response.err)
        });
      }
    }

    onAbcCodeChange(force = false) {
      if (!force && this.state.currentTab != TAB_ABC_CODE) return;

      setTimeout(() => {
        if (!this.abcEditor.tunes || this.abcEditor.tunes.length == 0) return;

        const tune = this.abcEditor.tunes[0];
        const key = tune.getKeySignature();
        var clef = 'treble';
        if (tune.lines.length && tune.lines[0].staff.length && tune.lines[0].staff[0].clef) {
          clef = tune.lines[0].staff[0].clef.type;

          switch (clef) {
            case "alto":
              if (tune.lines[0].staff[0].clef.clefPos == 2) clef = "alto1";
              if (tune.lines[0].staff[0].clef.clefPos == 4) clef = "alto2";
              break;
            case "bass":
              if (tune.lines[0].staff[0].clef.clefPos == 6) clef = "bass3";
              break;
            default:
              break;
          }
        }
        const notesStartIndex = (tune.lines.length && tune.lines[0].staff.length && tune.lines[0].staff[0].voices.length && tune.lines[0].staff[0].voices[0].length && tune.lines[0].staff[0].voices[0][0].startChar) || 9999999;

        // Get default note length from ABC string
        var abcUnitLength = 1;
        if (this.abcEditor.currentAbc.indexOf('L:') >= 0) {
          const startIdx = this.abcEditor.currentAbc.indexOf('L:') + 2;
          const endIdx = this.abcEditor.currentAbc.indexOf('\n', startIdx);
          const unitLength = this.abcEditor.currentAbc.substring(startIdx, endIdx).replace(/\s/g, '').split('/');
          if (unitLength.length == 2 && unitLength[1].length <= 2) {
            abcUnitLength = parseInt(unitLength[1]);
          }
        }

        this.setState({
          abcKey: key.root + key.acc + key.mode,
          abcClef: clef,
          abcMeter: [tune.meter.num,tune.meter.den],
          abcNotes: this.abcEditor.currentAbc.substring(notesStartIndex),
          abcUnitLength: abcUnitLength
        });
      }, 500);
    }

    stateToABC() {
      var abcString = '';
      //abcString += 'M: ' + this.state.abcMeter[0] + '/' + this.state.abcMeter[1] + '\n';
      abcString += 'L: 1/' + this.state.abcUnitLength + '\n';
      abcString += 'K: ' + this.state.abcKey + ' clef=' + this.state.abcClef + '\n';
      abcString += this.state.abcNotes;

      return abcString;
    }

    render() {
      const { t } = this.props;

      return <div className={this.props.classes.root}>
        <Snackbar 
          open={this.state.snackbarMessage.length > 0} 
          autoHideDuration={3000}
          message={this.state.snackbarMessage}
          onClose={() => this.setState({snackbarMessage: ''})}>
        </Snackbar>

        <Container style={{padding: '0px'}}>
          <h1>{ t("Set") + ": " + (this.props.set ? this.props.set.name : '') }</h1>
          <Paper elevation={2} className={this.props.classes.sheet}>
            <Box px={1} py={2}>
              <div style={{float: 'left', textAlign: 'left', marginLeft: '12px'}}>
                <ButtonGroup 
                  className="ExEd-toolbar" color="primary" variant="contained" aria-label="outlined primary button group">
                  <Button 
                    onClick={(e) => this.handleAccidentalButton(e, '_')}
                    disabled={!this.state.currentNoteSelected}
                  >
                    <div>b</div>
                  </Button>
                  <Button 
                    onClick={(e) => this.handleAccidentalButton(e, '^')}
                    disabled={!this.state.currentNoteSelected}
                  >
                    <div style={{marginTop: '-24px'}}>B</div>
                  </Button>
                  <Button 
                    onClick={(e) => this.handleAccidentalButton(e, '')}
                    disabled={!this.state.currentNoteSelected}
                  >
                    <BackspaceIcon />
                  </Button>
                </ButtonGroup>
              </div>
              <div style={{textAlign: 'right'}}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.saveChanges()}
                  style={{marginRight: '10px'}}
                  startIcon={<SaveIcon />}
                  disabled={!this.state.hasChanges}
                >
                    <Trans>Save changes</Trans>
                </Button>
              </div>
              <div id="ExEd-paper"></div>
              <div id="ExEd-synth"></div>
            </Box>
          </Paper>
        </Container>

        {/* RIGHT DRAWER */}
        <Drawer
          variant="permanent"
          open={true}
          classes={{
            root: this.props.classes.drawerRoot,
            paper: this.props.classes.drawerPaper
          }}
          anchor="right"
        >
          <Tabs 
            value={this.state.currentTab} 
            onChange={this.handleTabChange.bind(this)} 
            variant="fullWidth"
            scrollButtons="auto"
          >
            <Tab label={t("Set")} classes={{ root: this.props.classes.tabRoot }} />
            <Tab label={t("Exercise")} classes={{ root: this.props.classes.tabRoot }} />
            <Tab label="ABC" classes={{ root: this.props.classes.tabRoot }} />
          </Tabs>

          {/* SET TAB (inherited from Editor) */}
          <TabPanel value={this.state.currentTab} index={0}>
            { this.props.children }
          </TabPanel>

          {/* EXERCISE TAB */}
          <TabPanel value={this.state.currentTab} index={1}>
            <Typography variant="h6">{ t("Toolbar") }</Typography>

            {/* INSTRUMENT */}
            <FormControl className={this.props.classes.toolbarFormControl}>
              <InputLabel id="ExEd-instrumentId-label">{ t("Instrument") }</InputLabel>
              <Select
                labelId="ExEd-instrumentId-label"
                id="ExEd-instrumentId"
                value={this.state.instrumentId}
                onChange={this.handleInstrumentIdChange.bind(this)}
              >
                { 
                this.props.set && Object.values(this.props.instruments).map((instrument) => {
                  if (this.props.set.instrument_ids.includes(instrument.id)) {
                    return <MenuItem value={instrument.id}>{ t(instrument.name) }</MenuItem>
                  }
                  return null;
                })
                }
              </Select>
            </FormControl>

            {/* CLEF */}
            <FormControl className={this.props.classes.toolbarFormControl}>
              <InputLabel id="ExEd-abcClef-label">{ t("Clef") }</InputLabel>
              <Select
                labelId="ExEd-abcClef-label"
                id="ExEd-abcClef"
                value={this.state.abcClef}
                onChange={this.handleClefChange.bind(this)}
              >
                {
                Object.entries(clefs).map(([key, value]) => 
                  <MenuItem value={key}>{ t(value) }</MenuItem>
                )
                }
              </Select>
            </FormControl>

            {/* KEY */}
            <FormControl className={this.props.classes.toolbarFormControl}>
              <InputLabel id="ExEd-abcClef-label">{ t("Key") }</InputLabel>
              <Select
                labelId="ExEd-abcKey-label"
                id="ExEd-abcKey"
                value={this.state.abcKey}
                onChange={this.handleKeyChange.bind(this)}
              >
                {
                Object.entries(keys).map(([key, value]) => 
                  <MenuItem value={value}>{ t(key) }</MenuItem>
                )
                }
              </Select>
            </FormControl>
          </TabPanel>

          {/* ABC CODE TAB */}
          <TabPanel value={this.state.currentTab} index={2}>
            <Typography variant="h6">{ t("ABC code") }</Typography>
            <TextField
              id="ExEd-abc"
              label={t("ABC code")}
              multiline
              rows={9}
              value={this.state.abcCode}
              inputRef={this.abcTextField}
              onChange={this.handleAbcTextFieldChange.bind(this)}
              variant="filled"
              style={{ width: '100%' }}
            />
            <Alert id="ExEd-warnings" variant="outlined" severity="info" style={{maxWidth: '320px', margin: '10px auto'}}></Alert>
            <Typography variant="caption">
              <Link href="http://abcnotation.com/" target="_blank">{ t("What is the ABC notation?") }</Link>
            </Typography>
          </TabPanel>
        </Drawer>
      </div>;
    }
}

export default withTranslation()(withTheme(withStyles(styles)(withWidth()(ExerciseEditor))));