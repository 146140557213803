import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { Link as MaterialLink } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import { authenticationService } from '../../services/auth.js';
import Tooltip from '@material-ui/core/Tooltip';
import './MenuDrawer.css';

const useStyles = makeStyles((theme) => ({
    drawerRoot: {
      height: '100%'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 230,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
    },
    content: {
      flexGrow: 1,
      overflow: 'auto'
    },
    userDetails: {
      paddingTop: '20px'
    }
}));

function MenuDrawer(props) {
  const classes = useStyles();
  const currentUser = authenticationService.currentUserValue;
  const { t, i18n } = useTranslation();

  const activeRoute = (routeName) => {
    if (routeName == '/') return props.location.pathname == routeName;
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  const mainListItems = (
    <div>
      <Link to="/">
        <Tooltip title={t("Dashboard")} placement="right">
          <ListItem button selected={activeRoute('/')} onClick={() => props.handleToggle(false, false)}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={t("Dashboard")} />
          </ListItem>
        </Tooltip>
      </Link>
      <Link to="/exercises/instrument">
        <Tooltip title={t("Exercises")} placement="right">
          <ListItem button selected={activeRoute('/exercises/instrument')} onClick={() => props.handleToggle(false, false)}>
              <ListItemIcon>
                <LibraryMusicIcon />
              </ListItemIcon>
              <ListItemText primary={t("Exercises")} />
          </ListItem>
        </Tooltip>
      </Link>
      <Link to="/history">
        <Tooltip title={t("History")} placement="right">
          <ListItem button selected={activeRoute('/history')} onClick={() => props.handleToggle(false, false)}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary={t("History")} />
          </ListItem>
        </Tooltip>
      </Link>
      <Link to="/editor">
        <Tooltip title={t("Exercise editor")} placement="right">
          <ListItem button selected={activeRoute('/editor')} onClick={() => props.handleToggle(false, false)}>
              <ListItemIcon>
                <NoteAddIcon />
              </ListItemIcon>
              <ListItemText primary={t("Exercise editor")} />
          </ListItem>
        </Tooltip>
      </Link>
      <Hidden smUp>
        <Tooltip title={t("Logout")} placement="right">
          <ListItem button onClick={() => { props.handleToggle(false, false); props.showLogoutDialog(); }}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={t("Logout")} />
          </ListItem>
        </Tooltip>
      </Hidden>
    </div>
  );

  return (
    <div>
      <Hidden smUp>
        <SwipeableDrawer
            classes={{
              paper: clsx(classes.drawerPaper, !props.isOpen && classes.drawerPaperClose),
            }}
            open={props.isOpen}
            onOpen={() => props.handleToggle(true)}
            onClose={() => props.handleToggle(false)}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={() => props.handleToggle(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>{ mainListItems }</List>
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown style={{height: '100%'}}>
        <Drawer
            classes={{
              root: classes.drawerRoot,
              paper: clsx(classes.drawerPaper, !props.isOpen && classes.drawerPaperClose),
            }}
            variant="permanent"
            onOpen={() => props.handleToggle(!props.isOpen)}
            onClose={() => props.handleToggle(!props.isOpen)}
          >
            <div className={classes.content}>
              {
                props.isOpen &&
                <div className={classes.userDetails}>
                  <Link to="/account"><AccountCircleIcon fontSize="large" style={{fontSize: '80px'}} /></Link>
                  <div>{ authenticationService.currentUserValue.user.user.name }</div>
                  <div>{ authenticationService.currentUserValue.user.user.institution_name }</div>
                  <MaterialLink component="button" onClick={() => props.showLogoutDialog()}><Trans>Logout</Trans></MaterialLink>
                  <br/><br/>
                  <Divider/>
                </div>
              }
              <List>{ mainListItems }</List>
            </div>
        </Drawer>
      </Hidden>
    </div>
  );
}

export default withTranslation()(withRouter(MenuDrawer));