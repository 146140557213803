import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { useTranslation, Trans } from 'react-i18next';
import { apiService } from '../../services/api.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function SetOrderDialog(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const parentDialog = useRef(null);

  const DragHandle = SortableHandle(() => (
    <IconButton edge="end" aria-label="reorder" onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}>
      <DragHandleIcon className="dragHandle" />
    </IconButton>
  ));

  const SortableSetListItem = SortableElement(({ set, idx }) => (
      <ListItem ContainerComponent="div" divider dense>
        <ListItemText primary={t(set.name)} />
        <ListItemSecondaryAction>
            <DragHandle></DragHandle>
        </ListItemSecondaryAction>
      </ListItem>
  ));

  const SortableSetListContainer = SortableContainer(({items}) => {
    return (
      <List
        component="div" 
        aria-label={t("set list")}
        style={{maxHeight: '50vh', overflow: 'auto'}}
      >
        {
          props.sets && props.sets.map((e, idx) => {
            return <SortableSetListItem key={`set-${e.id}`} index={idx} idx={idx} set={e} />
          })
        }
      </List>);
  });

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans>Set order</Trans>
      </DialogTitle>
      <DialogContent>
        <div ref={parentDialog}>
            <SortableSetListContainer 
                onSortEnd={props.onSortEnd}
                items={props.sets} 
                lockAxis="y"
                lockToContainerEdges={true}
                helperClass="sortableHelper"
                helperContainer={parentDialog.current}>
            </SortableSetListContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.onClose}
        >
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  )
}