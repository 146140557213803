import React, { useEffect } from 'react';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { Route, Switch, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { authenticationService } from '../../services/auth.js';
import { apiService } from '../../services/api.js';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    root: {
    }
});

function EditDialog(props) {
    const currentUser = authenticationService.currentUserValue;
    const { t, i18n } = useTranslation();
  
    const [value, setValue] = React.useState('');

    var type = 'text';
    var helpText = t('Enter your new') + ' ' + props.field;
    if (props.field == 'name') helpText = t('Enter your') + ' ' + t('name');
    if (props.field == 'surname') helpText = t('Enter your') + ' ' + t('surname');
    if (props.field == 'password') {
        helpText = t('Enter your new password (it should be at least 6 characters long)');
        type = 'password';
    }
    if (props.field == 'email') helpText = t('Enter your new') + ' ' + t('e-mail address');

    const handleConfirm = () => {
        props.onConfirm(value);
    }
  
    return (
      <Dialog
        open={props.field != null}
        fullWidth
        disableBackdropClick={true}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          <Trans>{ helpText }</Trans>
        </DialogTitle>
        <DialogContent>
            <FormControl style={{width: '100%'}}>
                <TextField
                    type={type}
                    label={t(props.fieldName)}
                    id="dialog-value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onClose}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    )
}

function DeleteAccountDialog(props) {
    return (
      <Dialog
        open={props.open}
        fullWidth
        disableBackdropClick={true}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          <Trans>Delete my account</Trans>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <p><Trans>You are about to remove your account. All your data and your evaluations history will be lost. Do you really want to remove your accout?</Trans></p>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onClose}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={props.onConfirm}
          >
            <Trans>Delete my account</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    )
}

class Account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editingField: null,
            showDeleteAccountDialog: false
        }

        this.onDialogClose = this.onDialogClose.bind(this);
        this.onDialogConfirm = this.onDialogConfirm.bind(this);
        this.onDeleteAccountDialogClose = this.onDeleteAccountDialogClose.bind(this);
        this.onDeleteAccountDialogConfirm = this.onDeleteAccountDialogConfirm.bind(this);
    }

    componentDidMount() {
    }

    onDialogClose() {
        this.setState({
            editingField: null
        })
    }

    async onDialogConfirm(value) {
        const response = await apiService.putUser(authenticationService.currentUserValue.user.user.id, {
            [this.state.editingField]: value
        });

        if (response && !response.err) {
            // Update user data
            await authenticationService.renewAuthToken();

            this.setState({
                editingField: null
            });
        }
    }

    showEditDialog(field) {
        this.setState({
            editingField: field
        });
    }

    onDeleteAccountDialogClose() {
        this.setState({
            showDeleteAccountDialog: false
        })
    }

    onDeleteAccountDialogConfirm() {
        this.setState({
            showDeleteAccountDialog: false
        })
    }

    fieldName(field) {
        switch(field) {
            case "username":
                return "Username";
            case "name":
                return "Name";
            case "surname":
                return "Surname";
            case "email":
                return "E-mail";
            case "password":
                return "Password";
            default:
                return field;
        }
    }

    render() {
        const { t } = this.props;
        const { classes } = this.props;

        const currentUser = authenticationService.currentUserValue;

        return (
            <Container maxwidth="lg" className={classes.root}>
                <EditDialog
                  field={this.state.editingField}
                  fieldName={this.fieldName(this.state.editingField)}
                  onClose={this.onDialogClose}
                  onConfirm={this.onDialogConfirm}
                ></EditDialog>

                <DeleteAccountDialog
                  open={this.state.showDeleteAccountDialog}
                  onClose={this.onDeleteAccountDialogClose}
                  onConfirm={this.onDeleteAccountDialogConfirm}
                ></DeleteAccountDialog>

                <h1><Trans>My account</Trans></h1>
                <TableContainer component={Paper} style={{marginBottom: '2em'}}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row"><Trans>Username</Trans></TableCell>
                                <TableCell align="left">{ currentUser.user.user.username }</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row"><Trans>Name</Trans></TableCell>
                                <TableCell align="left">{ currentUser.user.user.name }</TableCell>
                                <TableCell align="right">
                                    <IconButton size="small" aria-label="edit" onClick={() => this.showEditDialog('name')}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row"><Trans>Surname</Trans></TableCell>
                                <TableCell align="left">{ currentUser.user.user.surname }</TableCell>
                                <TableCell align="right">
                                    <IconButton size="small" aria-label="edit" onClick={() => this.showEditDialog('surname')}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row"><Trans>E-mail</Trans></TableCell>
                                <TableCell align="left">{ currentUser.user.user.email }</TableCell>
                                <TableCell align="right">
                                    <IconButton size="small" aria-label="edit" onClick={() => this.showEditDialog('email')}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row"><Trans>Password</Trans></TableCell>
                                <TableCell align="left">********</TableCell>
                                <TableCell align="right">
                                    <IconButton size="small" aria-label="edit" onClick={() => this.showEditDialog('password')}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                  onClick={() => this.setState({showDeleteAccountDialog: true})}
                >
                    <Trans>Delete my account</Trans>
                </Button>
            </Container>
        );
    }
}

export default withTranslation()(withStyles(styles)(Account));