import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Route, Switch, Link } from 'react-router-dom';
import { apiService } from '../../services/api.js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          errorMessage: null,
          instruments: []
        }
    }

    componentDidMount() {
        this.getInstruments();
    }
    
    async getInstruments() {
        const response = await apiService.getInstruments({enabled: 1});
        if (response && !response.err) {
            this.setState({
                instruments: response.data
            });
        }
        else {
            this.setState({
                errorMessage: response.err
            });
        }
    }

    render() {
        const { t } = this.props;

        if (this.state.errorMessage)
          return <Alert severity="error">{ this.state.errorMessage }</Alert>

        return (
            <Container maxwidth="lg" style={{padding: '3em 12px'}}>
                <Grid container spacing={3} justify="center" alignItems="center">
                    <Grid item md={6}>
                        <Link to={"/exercises/instrument"}>
                            <Paper elevation={3} style={{padding: '30px 12px 8px 12px'}}>
                                <img src={`${process.env.PUBLIC_URL}/images/plectrus/boton_slecccionar_instrumentos.png`} width="80%" />
                                <br/>
                                <p style={{fontWeight: 'bold'}}><Trans>Practice</Trans></p>
                                <p><Trans>Choose your instrument and start practicing!</Trans></p>
                            </Paper>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withTranslation()(Dashboard);