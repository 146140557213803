import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { apiService } from '../../services/api.js';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Moment from 'react-moment';
import EvaluationsTable from './EvaluationsTable.js';

class History extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          users: [],
          evaluations: [],
          difficulties: [],
          instruments: [],
          sets: [],
          currentUser: 0,
          currentInstrument: 0,
          fromDate: null,
          toDate: null
        }
    }

    componentDidMount() {
      this.getUsers();
      this.getDifficulties();
      this.getInstruments();
      this.getEvaluations({"_order_by": "id DESC"});
      this.getSets();
    }

    async getUsers() {
        const response = await apiService.getUsers();
        if (response && !response.err) {
            this.setState({
                users: response.data
            });
        }
    }

    async getDifficulties() {
      const response = await apiService.getDifficulties({});
      if (response && !response.err) {
          this.setState({
              difficulties: response.data
          });
      }
    }

    async getInstruments() {
      const response = await apiService.getInstruments({});
      if (response && !response.err) {
          this.setState({
              instruments: response.data
          });
      }
    }

    async getExercises(setId) {
      this.setState({
        errorMessage: null
      });

      const response = await apiService.getExercises({set_id: setId});
      if (response && !response.err) {
        this.setState({
          exercises: response.data,
        });
      }
      else {
        if (response.err) {
          this.setState({
            errorMessage: response.err
          });
        }
        else {
          this.setState({
            errorMessage: "Unable to retrieve the exercises from the server"
          });
        }
      }
    }

    async getEvaluations(filters = {}, latest = true) {
      const response = await (latest ? apiService.getEvaluationsLatest(filters) : apiService.getEvaluations(filters));

      if (response && !response.err) {
        this.setState({
          evaluations: response.data
        });
      }
    }

    async getSets() {
        const response = await apiService.getSets();
        if (response && !response.err) {
            this.setState({
                sets: response.data
            });
        }
        else {
            this.setState({
                errorMessage: response.err
            });
        }
    }

    render() {
      const { t } = this.props;

      if (this.state.errorMessage)
        return <Container maxwidth="lg" style={{marginTop: 40}}><Alert severity="error">{ t(this.state.errorMessage) }</Alert></Container>
      
      return <div style={{padding: '3em 0'}}>
        <Container maxwidth="lg">
          <EvaluationsTable
            evaluations={this.state.evaluations}
            users={this.state.users}
            difficulties={this.state.difficulties}
            instruments={this.state.instruments}
            sets={this.state.sets}
          ></EvaluationsTable>
        </Container>
        </div>;
    }

    newSessionDialog() {
      return <Dialog
        open={this.state.showNewSessionDialog}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans>Start a new session</Trans>
        </DialogTitle>
        <DialogContent>
          <p><Trans>If you start a new session, your current evaluations for all exercises will be discarded. Your current evaluations will not be erased, as they are automatically saved for later consultation, and you will be always able to access your sessions history.</Trans></p>
          <p><Trans>Do you want to start a new session?</Trans></p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({showNewSessionDialog: false})}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.startNewSession.bind(this)}
          >
            <Trans>New session</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    }
}

export default withTranslation()(History);