import { BehaviorSubject } from 'rxjs';

const defaultSettings = {
    "countdownSeconds": 4,
    "tuningPitch": 440.0
};

// localStorage.removeItem('plectrusSettings');
const currentSettingsSubject = new BehaviorSubject(Object.assign({}, defaultSettings, JSON.parse(localStorage.getItem('plectrusSettings'))));

export const globalSettingsService = {
    defaultSettings,
    setDefaultSettings,
    updateSettings,
    currentSettings: currentSettingsSubject.asObservable(),
    get currentSettingsValue () { return currentSettingsSubject.value }
};

function setDefaultSettings() {
    // store settings in local storage
    localStorage.setItem('plectrusSettings', defaultSettings);

    currentSettingsSubject.next(defaultSettings);
}

function updateSettings(settings) {
    let newSettings = Object.assign({}, currentSettingsSubject.value, settings);

    // store settings in local storage
    localStorage.setItem('plectrusSettings', JSON.stringify(newSettings));

    currentSettingsSubject.next(newSettings);
}