import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Route, Switch, Link } from 'react-router-dom';
import { apiService } from '../../services/api.js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

class InstrumentList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          errorMessage: null,
          instruments: []
        }
    }

    componentDidMount() {
        this.getInstruments();
    }
    
    async getInstruments() {
        const response = await apiService.getInstruments({enabled: 1});
        if (response && !response.err) {
            this.setState({
                instruments: response.data
            });
        }
        else {
            this.setState({
                errorMessage: response.err
            });
        }
    }

    render() {
        const { t } = this.props;

        if (this.state.errorMessage)
          return <Alert severity="error">{ this.state.errorMessage }</Alert>

        return (
            <Container maxwidth="lg">
                <div style={{padding: '3em 0'}}>
                    <Typography variant="h4" style={{marginBottom: '2em'}}><Trans>Choose your instrument</Trans></Typography>
                    <Grid container spacing={3} justify="center" alignItems="center">
                        { 
                        this.state.instruments.map((instrument) => 
                            <Grid item xs={6} md={4}>
                                <Link to={`/exercises/instrument/${instrument.id}/sets`}>
                                    <Paper elevation={3} style={{padding: '30px 12px 8px 12px'}}>
                                        { /* ${process.env.PUBLIC_URL}/images/icons/music-instruments/svg/${instrument.gm_name}.svg */ }
                                        <img src={`${process.env.PUBLIC_URL}/images/plectrus/${instrument.gm_name}_t.png`} width="60%" style={{margin: '-20px'}} />
                                        <br/>
                                        <p style={{ fontWeight: 600 }}>{ t(instrument.name) }</p>
                                    </Paper>
                                </Link>
                            </Grid>
                        ) 
                        }
                    </Grid>
                </div>
            </Container>
        );
    }
}

export default withTranslation()(InstrumentList);