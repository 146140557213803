import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Trans, useTranslation } from 'react-i18next';
import { apiService } from '../../services/api.js';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="{ process.env.REACT_APP_BASE_URL }">
          Plectrus
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPasswordDialog(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [username, setUsername] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [successful, setSuccessful] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  

  const handleSubmit = async(event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessful(false);
    setSubmitting(true);

    try {
      const response = await apiService.getResetPassword(username);
      if (!response) {
        setErrorMessage("Unable to contact with the server. If the problem persists, please contact us.");
      }
      else if (response.err) {
        setErrorMessage(response.err);
      }
      else {
        setSuccessful(true);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("Unable to contact with the server. If the problem persists, please contact us.");
    }

    setSubmitting(false);
  }

  if (successful)
    return (
      <Dialog
        open={props.open}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">{ t("Reset password link sent") }</DialogTitle>
        <DialogContent>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <MarkunreadMailboxIcon />
            </Avatar>
            <br/>
            <Typography component="h1" variant="h6" paragraph={true} style={{textAlign: 'center'}}>
              { t("Please follow the reset link we have sent to your e-mail account to reset your password.") }
            </Typography>
            <Typography component="p" paragraph={true}>
              { t("You should receive the message in the next few minutes. Please check also your spam mailbox.") }
            </Typography>
            <Button 
              fullWidth
              variant="outlined"
              color="primary"
              className={classes.submit}
              onClick={() => props.handleOpen(false)}
            >
              { t("Close") }
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );

  return (
    <Dialog
        open={props.open}
        aria-labelledby="reg-dialog-title"
        aria-describedby="reg-dialog-description"
      >
        <DialogTitle id="reg-dialog-title">{ t("Password reset") }</DialogTitle>
        <DialogContent>
          <CssBaseline />
          <div className={classes.paper}>
            <p>{ t("Please enter your e-mail address or your username.") } { t("We will send you an e-mail with the instructions to successfully reset your password.") }</p>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label={ t("E-mail address") + " / " + t("Username") }
                    autoComplete="email"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                </Grid>
              </Grid>
              {
                errorMessage &&
                <Alert variant="outlined" severity="error" style={{marginTop: '12px'}}>{ t(errorMessage) }</Alert>
              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                disabled={!username || submitting}
              >
                { t("Send request") }
              </Button>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleOpen(false)} color="primary">
            { t("Cancel") }
          </Button>
        </DialogActions>
      </Dialog>
  );
}